<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Products Matching')"
            text-class="text-h6"
        />
          <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
          />



        <q-space />


        <q-btn
            :color="selectedItems.length > 0 ? 'amber-6' : 'dark'"
            text-color="white"
            :label="$t('Create order') + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`"
            class="q-mr-sm"
            size="sm"
            no-caps
            unelevated
            :disable="isDisabledFBAtoOnhand"
            @click="handleAddToOrderClear"
        />

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilter"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states,
              types: types,
              offerTypes: offerType
            }
          }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section
          class="q-pa-none"
      >
        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="itemsLoading"
            :filter="filter"
            :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
            selection="multiple"
            v-model:selected="selectedItems"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >

          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:header-selection="props">
            <q-checkbox
                size="xl"
                :model-value="selectedItems.length > 0"
                style="z-index: 1"
                color="amber-6"
                @click="handleSelectAll(props)"
            />
          </template>

          <template  v-slot:header-cell-wholesaleOrder="props">
              <q-th :props="props">
                  {{props.col.label}}
                  <q-btn-dropdown
                          class="q-ml-sm"
                          size="sm"
                          flat round dense
                          no-icon
                          dropdown-icon="more_vert"
                  >
                      <q-list>
<!--                          <q-item clickable v-close-popup  :class="`bg-${selectedItems.length>0? 'amber-6': 'dark'}`" :disable="isDisabledFBAtoOnhand" @click="handleFBAtoOnhand">-->
<!--                              <q-item-section>-->
<!--                                  <q-item-label>-->
<!--                                      {{ $t('FBA=On hand') + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`}}-->
<!--                                  </q-item-label>-->
<!--                              </q-item-section>-->
<!--                          </q-item>-->
                          <q-item clickable v-close-popup  :class="`bg-${selectedItems.length>0? 'amber-6': 'dark'}`" :disable="isDisabledFBAtoOnhand" @click="handleAddToOrder">
                              <q-item-section>
                                  <q-item-label>
                                      {{ $t('Create FBA order') + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`}}
                                  </q-item-label>
                              </q-item-section>
                          </q-item>

                      </q-list>
                  </q-btn-dropdown>
              </q-th>
          </template>

          <template v-slot:body="props">
            <products-matching-row
                :data="props"
                :items="selectedItems"
                @check="handleCheck"
                @open="handleShowInfo"
            />
          </template>

        </q-table>
      </q-card-section>

    </q-card>
  </div>
  <order-products-empty-modal ref="orderModal"/>
  <order-products-movements-modal ref="orderModal2"/>
  <offer-modal ref="offerModal" @submit="handleOfferSubmit" />
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import Search from '../../components/search/Search'
import ProductsMatchingRow from '../../components/products/ProductsMatchingRow'
import OfferModal from "../../components/modals/OfferModal.vue";
import OrderProductsMovementsModal from "../../components/modals/OrderProductMovementsModal.vue";
import OrderProductsEmptyModal from "@/apps/app/components/modals/OrderProductsEmptyModal.vue";

// Utils
import { buildQuery } from '../../utils/query-utils'



export default {
  name: 'ProductMatching',
  components: {
    OrderProductsEmptyModal,
    OrderProductsMovementsModal,
    OfferModal,
    FiltersCollapse,
    Search,
    ProductsMatchingRow
  },
  mixins: [
    TableMixin
  ],
  data() {
    return {
      filter: '',
      items: [],
      selectedItems: [],
      totalItems: null,
      isOpenFilter: false,
      itemsLoading: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 50
      },
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Product'),
          name: 'product',
          align: 'left'
        },
        {
          label: this.$t('FBA'),
          name: 'wholesaleOrder',
          align: 'center'
        },
        {
          label: this.$t('FBM'),
          name: 'retailOrder',
          align: 'center'
        },
        {
          label: this.$t('Inventory'),
          name: 'inventory',
          align: 'center'
        }
      ],
      offerType: [
          { id: 'FBA', title: this.$t('FBA')},
          { id: 'FBM', title: this.$t('FBM')}
      ],
      states: [
          { id: 'normal', title: this.$t('On hand') },
          { id: 'expected', title: this.$t('Expected') },
          { id: 'new', title: this.$t('Received') },
          { id: 'blocked', title: this.$t('Blocked') },
          { id: 'booked', title: this.$t('Booked') }
      ],
      activatedFields: [
          'id',
          'type',
          'name',
          'shop',
          'created.from',
          'created.to',
          'quantityExpected.from',
          'quantityExpected.to',
          'quantityReceived.from',
          'quantityReceived.to',
          'quantityAllocated.from',
          'quantityAllocated.to',
          'quantityShipped.from',
          'quantityShipped.to',
          'orderQuantityFBA.to',
          'orderQuantityFBA.from',
          'orderQuantityFBM.to',
          'orderQuantityFBM.from',
          'state',
          'offerType'
      ]
    }
  },
  mounted() {
    this.onRequest({ pagination: {} })
  },
  computed: {
    ...mapGetters([
    ]),
    types () {
      return [
        {id: 'simple', code: 'Simple'},
        {id: 'bundle', code: 'Bundle'},
      ]
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.totalItems)
          : this.$t('Filter')
    },
    isDisabledFBAtoOnhand () {
        // Order can be created only with offers from one shop
        return this.selectedItems.length <= 0 || this.selectedItems.find(x => x._embedded.shop.id !== this.selectedItems[0]._embedded.shop.id)
    }
  },
  methods: {
    ...mapActions([
    ]),
    ...mapMutations([
        'upsertOffer'
    ]),
    handleAddToOrderClear () {
        this.$refs.orderModal.show(this.selectedItems.map(item => ({ ...item, count: 1 })))
    },
    handleAddToOrder () {
      this.selectedItems = this.selectedItems.filter(e=>e.eav && e.eav['integrations-amazon-offer-fba-quantity'])
      this.$refs.orderModal2.show(this.selectedItems.map(item => {
          if (item.eav && item.eav['integrations-amazon-offer-fba-quantity']) {
              return { ...item, count: item.eav['integrations-amazon-offer-fba-quantity'] }
          }
      }))
    },

    handleFBAtoOnhand () {
        if(this.selectedItems) {
            this.selectedItems.forEach(e=> {
                this.$service.offer.save({ eav:{'integrations-amazon-offer-fba-quantity' : e.quantityAllocated }},e.id, e._embedded.shop.id, {})
                    .then(item => {
                        this.upsertOffer(item)
                        this.$router.go()
                    })
            })

        }
    },
    handleOfferSubmit () {

    },
    handleShowInfo (offer) {
        this.$refs.offerModal.open(offer)
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    handleFiltersSubmit (filter) {
      let updatedFilter = [
        ...filter
      ]

      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter: updatedFilter, page: 1 }})
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    onRequest(data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      this.updateParams(query)
      this.$service.offerInventory.getAll(this.serverParams)
          .then(({items, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
            this.totalItems= totalItems
            this.items=items
          })
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleCheck (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems = [item, ...this.selectedItems]
      }
    },
  }
}
</script>