<template>
  <q-dialog
    v-model="isOpen"
    persistent
    class="modal-md"
  >
    <q-card class="text-center">
      <q-card-section class="row q-px-xs">
        <div class="col text-h6 no-copy">
          {{ 'How many labels do you want to print?' }}
        </div>

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="handleClose"
        />
      </q-card-section>

      <q-card-section class="q-pb-none">
          <q-input
            type="number"
            v-model="quantity"
            autofocus
            :rules="[
              (val) => /^\d+$/.test(val) || 'Invalid input. Please enter a number.',
            ]"
            outlined
            input-style="text-align: center; font-size: 2rem"
            @input="handleInput"
          />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="positive"
          :label="$t('Print')"
          no-caps
          :disable="!quantity"
          @click="handleOk"
        />

        <q-btn
          color="negative"
          :label="$t('Discard')"
          no-caps
          @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'PrintQuantityModal',
  props: {
    action: {
      type: String,
      default () {
          return 'check'
      }
    }
  },
  data () {
    return {
      isOpen: false,
      quantity: 1,
      acceptance: null,
      warehouse: null,
      formattedDate: ''
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    open (acceptance , warehouse, formattedDate, item) {
      this.isOpen = true
      this.acceptance = acceptance
      this.warehouse = warehouse
      this.formattedDate = formattedDate
      this.item = item
    },
    handleClose () {
      this.isOpen = false
    },
    handleOk () {
      this.$service.printer.generateBarcode({
        barcode: this.item.sku,
        label: `${this.warehouse} / ${this.item.count} \n ${this.acceptance?.id} / ${this.formattedDate} ${this.item.expires ? this.item.expires : ''}`,
        count: this.quantity,
        size: '50.8x25.4',
        fontSize: '11'
      })

      this.isOpen = false
    },
    handleInput (value) {
      this.quantity = value
    }
  }
}
</script>

