<template>
  <div
    v-if="this.rawData?.options?.virtual"
    :class="`card shadow rounded ${bg} text-dark`"
    @click="handleClick"
  >
    <div class="text-h6 text-center q-pa-md shadow rounded">
        <strong>{{ `#${data.id}` }}</strong>
    </div>
  </div>
  <div
    v-else
    :class="`card shadow rounded ${bg} text-dark`"
    @click="handleClick"
  >
     <div class="row text-center text-caption no-wrap">
      <template
        v-for="(item, i) in path"
        :key="`${item}: ${i}`"
      >
        <div
          class="col q-px-lg q-py-sm"
          style="white-space: pre-wrap !important;"
        >
          <strong>{{ item || 0 }}</strong>
        </div>

        <div
          v-if="i + 1 < path.length"
          class="col q-py-sm"
        >
          -
        </div>
      </template>
    </div>

    <div
      v-if="type"
      class="text-caption text-center q-py-xs border-top"
    >
      {{ `${type} (${data?.code})`}}
    </div>

    <div
      v-if="appOptions.debug || forceId"
      class="text-subtitle1 text-center q-py-xs border-top"
    >
      <strong>{{ data.id }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceObject',
  emits: ['click'],
  props: {
    forceId: {
      type: Boolean,
      default () {
        return false
      }
    },
    bg: {
      type: String,
      default () {
        return 'bg-white'
      }
    },
    type: {
      type: String,
      default () {
        return null
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      appOptions: this.$appOptions,
      path: [],
      sub: null
    }
  },
  watch: {
    data (value) {
      this.loadPath(value)
    }
  },
  mounted () {
    this.loadPath(this.data)
    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })
  },
  unmounted () {
    this.sub.unsubscribe()
  },
  methods: {
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }
      if( typeof place.path  === 'string' ) {
        const cleanedString = place.path.replace(/'/g, ''); // Remove single quotes
        const resultArray = JSON.parse(cleanedString);
        this.path = [...resultArray, (place.placeAlias || '')]
        return
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    handleClick () {
      this.$emit('click', this.rawData)
    }
  }
}
</script>
