<template>
  <q-tr
      :props="data"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex + 1 }}
    </q-td>
    <q-td
        key="image"
        :props="data"
        class="col-5"
        style="max-width: 15%; text-align: center; width: 85px"
    >
      <img
          :src="data.row.image ? imageCheck(data.row.image) : appOptions.fallbackImage"
          style="width: 80px; height: 70px; object-fit: contain;"
          @error="onImageLoadFailure"
      >
    </q-td>
    <q-td
        key="component"
        :props="data"
        class="col-5"
        style="max-width: 100px; word-wrap: break-word; white-space: normal !important; background-color: white"
    >
      <div class="text-subtitle2 q-my-sm">
        {{ `${data.row.name} (${data.row.id})` }}
      </div>
      <div>
        <q-chip color="orange-6" text-color="white">
          {{ data.row.type }}
        </q-chip>
      </div>
      <div >
        <q-chip v-if="data.row.eav && data.row.eav['integrations-source-type']"  color="grey-8" text-color="white">
          {{`${data.row.eav['integrations-source-type']} / (${this.data.row.eav['integrations-source-id']})`}}
        </q-chip>
        <q-chip v-else  color="red-6"  text-color="white">
          {{'Manually'}}
        </q-chip>
      </div>
    </q-td>
    <q-td
        key="count"
        :props="data"
        class="col-5"
        style="max-width: 50px; word-wrap: break-word; white-space: normal !important; background-color: white"
    >
      <h4><strong>{{this.quantity}}</strong></h4>
    </q-td>
    <q-td
        key="parameters"
        :props="data"
        auto-width
        style="max-width: 15%; text-align: center; width: 85px"
    >

            <div style="display: contents;">
              <q-table
                  class="my-q-table"
                  row-key="id"
                  :rows="orderRows"
                  :columns="orderCols"
                  hide-bottom
                  frameless
                  binary-state-sort
                  style="border: none !important; box-shadow: none !important; background-color: inherit !important; max-width: 250px"
              >
                <template v-slot:header>
                  <q-tr >
                    <q-th style="background-color: inherit !important; ">{{ 'Name' }}</q-th>
                    <q-th style="background-color: inherit !important; ">{{'Parameter'}}</q-th>
                  </q-tr>
                </template>
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td key="name">{{'UPC/EAN'}}</q-td>
                    <q-td key="parameter" style="max-width: 100px; white-space: normal !important">{{`${(data.row.upcs && data.row.upcs.length>0)?data.row.upcs[0]: '-'} / ${(data.row.eans && data.row.eans.length>0)?data.row.eans[0]: '-'}`}}</q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
    </q-td>

    <q-td
        key="inventory"
        :props="data"
        auto-width
        class="col-5 text-center border-top-0"
    >
      <q-toggle :model-value="infoToggle"  @update:model-value="handleInfoToggle"/>

      <span>
        <table v-if="infoToggle" class="table table-valign-middle">
          <thead>
            <tr>
              <th style="background-color: inherit !important; z-index: 0 ">{{ $t('Status') }}</th>

              <th style="background-color: inherit !important; z-index: 0">{{ $t('Quantity') }}</th>

              <th style="background-color: inherit !important; z-index: 0">{{ $t('Warehouse') }}</th>

            </tr>
          </thead>

          <tbody style="background-color: inherit !important; ">
            <tr>
              <td class="bg-white" style="background-color: inherit !important; ">
                <label :class="`q-py-xs q-px-sm rounded bg-primary`">
                  {{ 'Received' }}
                </label>
              </td>
              <td>{{data.row.quantityReceived}}</td>
              <td>{{'-'}}</td>
            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-secondary`">
                  {{'Shipped'}}
                </label>
              </td>
              <td>{{shipped}}</td>
              <td>{{shippedWarehouse}}</td>
            </tr>
            <tr>
              <td >
                <label :class="`q-py-xs q-px-sm rounded bg-warning`">
                  {{'Difference'}}
                </label>
              </td>
              <td>{{data.row.quantityReceived - shipped}}</td>
              <td>{{'-'}}</td>
            </tr>
            <tr>
              <td >
                <label :class="`q-py-xs q-px-sm rounded bg-positive`">
                  {{'On hand'}}
                </label>
              </td>
              <td>{{onHand}}</td>
              <td>{{onHandWarehouse}}</td>
            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-info`">
                     {{'New'}}
                </label>
              </td>
              <td>{{ newState }}</td>
              <td>{{newStateWarehouse}}</td>
            </tr>
            <tr >
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-green-7`">
                  {{'Blocked'}}
                </label>
              </td>
              <td>{{blocked}}</td>
              <td>{{blockedWarehouse}}</td>
            </tr>
            <tr >
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-danger`">
                  {{'Booked'}}
                </label>
               </td>
              <td>{{booked}}</td>
              <td>{{bookedWarehouse}}</td>
            </tr>
          </tbody>
        </table>
      </span>
    </q-td>

  </q-tr>
</template>

<script>

import {buildQuery} from "@/apps/app/utils/query-utils";
import {mapGetters} from "vuex";

export default {
  name: 'ProductsMatchingBundleTable.vue',
  props: {
    data: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      default () {
        return {}
      },
    },
    column: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      items: [],
      quantity: null,
      orderCols: [
        {
          name: 'name',
          label: this.$t('Name'),
          align: 'left',
          field: 'name'
        },
        {
          name: 'parameter',
          label: this.$t('Parameter'),
          align: 'left',
          field: 'parameter'
        }
      ],
      orderRows: [
        {
          id:1,
          MSKU: ((this.data.row.eav && this.data.row.eav['integrations-amazon-offer-asin'] && this.data.row.eav['integrations-amazon-offer-asin']['ATVPDKIKX0DER']) ? (this.data.row.eav['integrations-amazon-offer-asin'].ATVPDKIKX0DER) : ('-')),
          ASIN: ((this.data.row.eav && this.data.row.eav['integrations-amazon-offer-asin'] && this.data.row.eav['integrations-amazon-offer-asin']['ATVPDKIKX0DER']) ? (this.data.row.eav['integrations-amazon-offer-asin'].ATVPDKIKX0DER) : ('-')),
        },
      ],
      infoToggle: false,
      onHand: 0,
      onHandWarehouse: '-',
      newState: 0,
      newStateWarehouse: '-',
      booked: 0,
      bookedWarehouse: '-',
      blocked: 0,
      blockedWarehouse: '-',
      shipped: 0,
      shippedWarehouse: '-',
    }
  },
  mounted() {
    this.product.eav['products-offer-bundle'].forEach(e=> {
      if( e.productOffer === this.data.row.id) {
        this.quantity = e.count
      }
    })
      if (this.data.row.items) {
          let booked = this.data.row.items.filter(e => e.state === 'booked')
          let blocked = this.data.row.items.filter(e => e.state === 'blocked')
          let onHand = this.data.row.items.filter(e=> e.state === 'normal')
          let newState = this.data.row.items.filter(e=> e.state === 'new')
          let shipped = this.data.row.items.filter(e=> e.state === 'shipped')
          if (booked.length > 0) {
              this.booked = booked[0].count
              this.bookedWarehouse = booked[0].warehouse
          }
          if (blocked.length > 0) {
              this.blocked = blocked[0].count
              this.blockedWarehouse = blocked[0].warehouse
          }
          if (onHand.length > 0) {
              this.onHand = onHand[0].count
              this.onHandWarehouse = onHand[0].warehouse
          }
          if (newState.length > 0) {
              this.newState = newState[0].count
              this.newStateWarehouse = newState[0].warehouse
          }
          if (shipped.length > 0) {
              this.shipped = shipped[0].count
              this.shippedWarehouse = shipped[0].warehouse
          }
      }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
    chipColor () {
      if (this.data.row.type === 'bundle'){
        return 'accent'
      }
      return 'orange-6'
    },
    chipSourceColor () {
      if( this.data.row.eav['integrations-source-type']) {
        return ''
      }
      return 'negative'
    },
  },
  methods: {
    imageCheck (image) {
      if (!image) {
        return ''
      }
      if (image.includes('url=')) {
        const realImage = image.split('url=')
        return `${realImage[1]}`
      } else {
        return `${image}`
      }
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    handleInfoToggle () {
      this.infoToggle = !this.infoToggle
    },
  }
}
</script>