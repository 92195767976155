<template>
  <div :class="`${noPadding ? '' : 'q-pa-sm'} row`">
    <q-legend :label="$t('Products')" />

    <div class="col-12 col-md" :style="isMobile ? '' : 'max-width: 350px;'">
      <search
        style="padding: 0;"
        :is-expandable="isExpandable"
        dense
        :placeholder="placeholder"
        @submit="handleSearch"
      />
    </div>

    <q-space />

    <div class="row col-12 col-md justify-end">
      <slot />

      <q-btn
        color="green-6"
        text-color="white"
        class="q-ml-sm"
        :class="isMobile ? 'col-12 q-mt-md' : ''"
        :label="$t('Add product')"
        :disable="disabled"
        icon="add"
        no-caps
        @click="openList"
      />
    </div>
  </div>

  <q-dialog
    v-model="isOpen"
    :maximized="true"
  >
    <products-grid
      v-if="isOpen"
      :rows="products"
      :options="options"
      @submit="handleSubmit"
      @close="closeList"
      @on-item-change="handleItemChange"
    />
  </q-dialog>
</template>

<script>
// Components
import Search from '../search/Search.vue'
import ProductsGrid from './ProductsGrid.vue'

export default {
  name: 'ProductSearch',
  emits: ['submit', 'on-search', 'on-close-search', 'create', 'on-item-change'],
  components: {
    ProductsGrid,
    Search
  },
  props: {
    defaultOpen: {
      type: Boolean,
      default () {
        return false
      }
    },
    isExpandable: {
      type: Boolean,
      default () {
        return true
      }
    },
    placeholder: {
      type: String
    },
    noPadding: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    products: {
      type: Array,
      default () {
        return []
      }
    },
    filter: {
      type: Array,
      default () {
        return []
      }
    },
    shops: {
      type: Array,
      default () {
        return []
      }
    },
    options: {
      type: Object,
      default () {
        return {
          batch: false,
          defaultValues: {}
        }
      }
    }
  },
  computed: {
    isMobile () {
      return window.innerWidth < 700
    },
    isOpen () {
      return this.options.openOffersSearch || this.isOpened
    }
  },
  data () {
    return {
      isOpened: false
    }
  },
  methods: {
    openList () {
      this.isOpened = true
    },
    closeList () {
      this.isOpened = false

      this.$emit('on-close-search', {})
    },
    handleSearch (search) {
      this.$emit('on-search', search)
    },
    handleSubmit (data) {
      this.$emit('submit', data)
      this.closeList()
    },
    handleItemChange (data) {
      this.isOpened = false

      this.$emit('on-item-change', data)
    }
  }
}
</script>
