<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 60vw;">
      <q-card-section>
        <div>
          <place-object
              :data="place"
              :type="place.type"
              class="q-my-sm"
          />

          <quantity-section
              :data="quantity"
              :options="quantityOptions"
              @click="handleCounterSubmit"
          />

          <counter-section :disable-additional-action="true" :data="counterValue" :options="counterOptions" @click="handleRequestData"/>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>

  <request-data-modal
      ref="requestDataModal"
      @submit="handleRequestData"
      @close="$emit('unblock')"
  />
</template>

<script>
// Vuex
import { mapActions, mapMutations } from 'vuex'

// Components
import QuantitySection from "@/apps/terminal/components/sections/QuantitySection.vue";
import CounterSection from "@/apps/terminal/components/sections/CounterSection.vue";
import PlaceObject from "@/apps/terminal/components/objects/place-object.vue";
import RequestDataModal from "@/apps/app/components/modals/RequestDataModal.vue";

export default {
  name: 'OfferPlaceChangeQuantity',
  emits: ['submit'],
  components: {
    RequestDataModal,
    PlaceObject,
    CounterSection,
    QuantitySection
  },
  data () {
    return {
      isOpen: false,
      offer: {},
      options: {},
      places: [],
      place: {},
      isLoading: false,
      path: [],
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received',
        defected: 'Defected',
      },
      counterOptions: {
        allowNegative: true,
        allowNoLimit: true,
        allowAdditionalAction: {
          label: this.$t(`Move`)
        }
      },
      filter: '',
      counterValue: 0,
      totalCount: 0,
    }
  },
  computed : {
    placesToMove () {
      if(this.places && this.place) {
        let places = this.places.filter(e=> e._embedded?.place?.id && this.place.id !== e._embedded.place.id )
        return places
      }
      return []
    },
    quantityOptions () {
      return {
        maxDescription: 'tap to edit',
        max: 'Available',
        current: 'Result'
      }
    },
    totalNormalQuantity () {
      if (!this.places) {
        return 0
      }

      const foundPlace = this.places.find(place => place._embedded.place.id === this.place.id)
      return foundPlace.count
    },
    quantity () {
      const counterObj = {
        barcode: '',
        max: 0,
        current: 0
      }

      if (this.offer) {
        counterObj.max = this.totalNormalQuantity
        counterObj.current = this.totalCount

        if (counterObj.current === 0) {
          counterObj.current = this.totalNormalQuantity
        }
      }

      return counterObj
    },
  },
  methods: {
    ...mapMutations([
      'setOffer'
    ]),
    ...mapActions([
      'loadOffer'
    ]),
    handlePlaceRequest (event) {
      this.selectedQuantity = event.data.value
      let places = this.placesToMove
      this.$refs.requestDataModal.open({
        data: [
          {
            'type': 'Laminas\\Form\\Element\\Text',
            'name': 'targetPlace',
            'options': {
              'label': 'Scan target location',
              'required': true
            }
          }
        ]
      },places,this.columns,this.pagination,this.itemStates,this.stateName)
    },
    handleRequestData (event) {
      if (event.event === 'counter.submit') {
        let cnt = event.data.value
        this.$service.storageItemEntity.patchBatch({}, {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(data => {
          if (this.totalCount) {
            this.totalCount = this.totalCount + parseInt(cnt)
          } else {
            this.totalCount = this.quantity.max + parseInt(cnt)
          }
          this.isDoneActive = true
          this.close()
          this.$emit('submit')
        })
      } else if (event.event === 'counter.additional.submit') {
        return this.handlePlaceRequest(event)
      } else if (typeof event.quantity !== 'undefined' && !isNaN(event.quantity)) {
        let cnt = event.quantity
        this.$service.storageItemEntity.putBatch({}, {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(data => {
          this.totalCount = cnt
          this.isDoneActive = true
          this.close()
          this.$emit('submit')
        })
      } else if (event.targetPlace) {
        let cnt = this.selectedQuantity

        const match = event.targetPlace.match(/\d+/)
        const numericPart = match ? match[0] : null

        this.$service.storageItemEntity.patchBatch([{
          place: numericPart
        }], {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(() => {

          this.selectedQuantity = 0
          this.isDoneActive = true
          this.$emit('submit')
          this.close()
        })
      }

    },
    handleCounterSubmit (event) {
      this.$refs.requestDataModal.open({
        data: [
          {
            'type': 'Laminas\\Form\\Element\\Number',
            'name': 'quantity',
            'options': {
              'label': 'Write exact quantity',
              'required': true
            }
          }
        ]
      })
    },
    handleSubmit (offer) {
      this.$emit('submit', offer)
      this.close()
    },
    close () {
      this.isOpen = false
    },
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    open (offer = {}, place = {}, places = []) {
      this.offer = {}
      this.place = {}
      this.places = []
      this.offer = offer
      this.places = places
      this.place = place
      this.isOpen = true
      this.loadPath(this.place)
    }
  }
}
</script>
