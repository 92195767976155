<template>
  <portal-target
    :name="option.name"
    @change="handleChange"
  />
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    option: {
      type: Object,
      required: true
    },
    hasIcon: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  methods: {
    handleChange (e) {
      typeof this.option.onChange === 'function' && this.option.onChange(e)
    }
  }
}
</script>
