<template>
  <div v-if="offer">
    <div class="q-pa-md">
      <div class="row">
        <div class="col-sm-5 row items-center">
          <img
            :src="offer.image || fallbackImage"
            style="width: 100%; max-height: 250px; object-fit: contain;"
            @error="onImageLoadFailure"
          >
        </div>

        <div class="col-sm-7">
          <form-builder :schema="schema" />
        </div>
      </div>
    </div>

    <items-info-modal ref="itemsInfo" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ItemsInfoModal from '../../components/modals/ItemsInfoModal.vue'

export default {
  name: 'Offer',
  emits: ['change'],
  components: {
    ItemsInfoModal
  },
  props: {
    mode: {
      type: String,
      default () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      types: [
        { id: 'simple', name: 'Simple' },
        { id: 'grouped', name: 'Grouped' },
        { id: 'bundle', name: 'Bundle' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'offer',
      'appOptions'
    ]),
    options () {
      return {
        defaultValues: {
          shop: this.offer._embedded.shop
        }
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                required: true,
                onChange: name => {
                  this.updateOffer({ name })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'tag',
                value: this.offer.barcodes || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Barcodes'),
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  this.updateOffer({ barcodes: [...this.offer.barcodes, value ] })
                  this.$emit('change', this.offer)
                },
                onRemove: (value) => {
                  this.updateOffer({ barcodes: this.offer.barcodes.filter(x => x !== value) })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.offer && this.offer.type,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                field: 'type',
                disabledClean: true,
                customLabel: (row) => {
                  return row && typeof row === 'object'
                    ? this.$t(row.name)
                    : row
                },
                options: this.types,
                onChange: (type) => {
                  this.updateOffer({ type: type.id })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.offer.purchasingPrice ,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                label: this.$t('Value'),
                disabled: this.disabled,
                onChange: purchasingPrice  => {
                  this.updateOffer({ purchasingPrice })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.offer.price ,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                label: this.$t('Price'),
                disabled: this.disabled,
                onChange: price  => {
                  this.updateOffer({ price })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Store'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.offer._embedded.shop,
                disabled: !!this.offer && !!this.offer._embedded.shop,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [{ field: 'state', type: 'in', values: ['active', 'blocked'] }]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: shop => {
                  this.updateOfferEmbedded({ shop })
                  this.$emit('change', this.offer)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateOffer',
      'updateOfferEmbedded'
    ]),
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    }
  }
}
</script>
