<script>
export default {
  data () {
    return {
      serverParams: this.$route.query
    }
  },
  methods: {
    updateParams (newProps) {
      this.serverParams = {
        ...this.$route.query,
        ...newProps
      }

      if (!newProps.search && this.serverParams.search) {
        delete this.serverParams.search
      }

      this.$router.replace({ query: this.serverParams })
    }
  }
}
</script>
