<template>
  <q-tr
      :props="data"
  >
    <td class="q-td text-left">
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </td>

    <q-td
        key="image"
        :props="data"
        class="col-6"
        style="max-width: 10%; text-align: center; width: 85px"
    >
      <img
          :src="data.row.image ? imageCheck(data.row.image) : appOptions.fallbackImage"
          style="width: 80px; height: 70px; object-fit: contain;"
          @error="onImageLoadFailure"
      >
    </q-td>

    <q-td
      key="product"
      :props="data"
      class="col-6"
      style="max-width: 100px; word-wrap: break-word; white-space: normal !important; background-color: white"
      >
      <div class="text-subtitle2 q-my-sm">
        {{ `${data.row.name} (${data.row.id})` }}
      </div>
      <div>
        <div v-if="data.row.type === 'bundle'" class="q-pb-sm">
            <q-btn-dropdown
                    split
                    color="teal"
                    rounded
                    size="sm"
                    label="bundle"
                    @click.left="handleShowInfo"
                    @before-hide="handleBundleToggle($event)"
                    @before-show="handleBundleToggle($event)"
            >
            </q-btn-dropdown>
        </div>
          <div v-else>
              <q-chip :color="chipColor"  text-color="white" clickable @click="handleShowInfo">
                  {{ data.row.type }}
              </q-chip>
          </div>

<!--        <q-toggle v-if="data.row.type === 'bundle'" :model-value="bundleToggle" @update:model-value="handleBundleToggle"/>-->
      </div>
      <div >
        <q-chip v-if="data.row.eav && data.row.eav['integrations-source-type']" :color="chipSourceColor" text-color="white">
            {{`${data.row.eav['integrations-source-type']} / (${this.data.row.eav['integrations-source-id']})`}}
        </q-chip>
        <q-chip v-else  :color="chipSourceColor" text-color="white">
           {{'Manually'}}
        </q-chip>
      </div>
      <div class="text-subtitle2 q-my-sm">
          {{ `${data.row._embedded.shop.name} (${data.row._embedded.shop.id})` }}
      </div>
    </q-td>


    <q-td
        key="wholesaleOrder"
        :props="data"
        class="col-5"
        auto-width
        style="max-width: 25%; text-align: center; width: 25%"
    >
        <div class="q-pl-xl q-pb-lg center" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <q-input v-model="fbaInput" type="number" :class="ifFBAInputColor" input-style="text-align: center" borderless style="font-size: 1.5rem; font-weight: 300;box-shadow: 0px 0px 0px 0px #ccc inset;max-width: 30% ; border-bottom: 1px dotted #ccc; "  @update:model-value="handleChange(fbmInput, 'fba', $event)"/>
        </div>


        <div class="q-pl-xl q-pb-lg center"  style="display: flex; flex-direction: column; justify-content: center; align-items: center ; width: 100% !important;">

        <q-table
            class="my-q-table"
            row-key="id"
            :rows="orderRows"
            :columns="orderCols"
            hide-bottom
            frameless
            hide-header
            binary-state-sort
            style="border: none !important; box-shadow: none !important; background-color: inherit !important; width: 100%"
        >

          <template v-slot:body="props">
            <q-tr :props="props" >
              <q-td key="name"  style="text-align: left">
                  <div class="row" >
                      <div :class="mskuBackgroundFba">
                          {{'MSKU'}}
                      </div>
<!--                      <div class="col-3 text-danger q-gutter-md " style="font-size: 2em"-->
<!--                           v-if="data.row.eav && data.row.eav['integrations-amazon-offer-fba-quantity'] && !data.row.eav['integrations-amazon-offer-sku-fba']">-->
<!--                          <q-icon name="horizontal_rule" class="bg-red rounded-borders"/>-->
<!--                          <q-tooltip>{{'Missing MSKU'}}</q-tooltip>-->
<!--                      </div>-->
                  </div>
              </q-td>
              <q-td  style="max-width: fit-content; white-space: normal !important">
                <div class="row">
                    <div class="col">
                        <q-select
                                v-model="selectedOption"
                                :options="optionsFbaArray"
                                option-value="key"
                                option-label="value"
                                :emit-value="true"
                                menu-cover
                        >
                            <template v-slot:no-option>
                                <q-item clickable @click="addOptionDialog = true">
                                    <q-item-section>Add Option</q-item-section>
                                    <q-item-section side>
                                        <q-icon name="add" color="primary" />
                                    </q-item-section>
                                </q-item>
                            </template>
                            <template v-slot:option="scope">
                                <q-item :key="scope" clickable @click="handleClick('fba', scope)" class="cursor-pointer">
                                    <q-item-section>{{ scope.label}}</q-item-section>
                                </q-item>
                            </template>
                            <template v-slot:after-options>
                                <q-item clickable @click="addOptionDialog = true">
                                    <q-item-section>Add Option</q-item-section>
                                    <q-item-section side>
                                        <q-icon name="add" color="primary" />
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                        <q-dialog v-model="addOptionDialog" persistent>
                            <q-card>
                                <q-card-section>
                                    <q-input v-model="newOption.key" label="Key" />
                                    <q-input v-model="newOption.label" label="Label" />
                                </q-card-section>
                                <q-card-actions align="right">
                                    <q-btn label="Cancel" @click="addOptionDialog = false" />
                                    <q-btn label="Add" color="primary" :disable="!newOption.key || !newOption.label" @click="addNewOption" />
                                </q-card-actions>
                            </q-card>
                        </q-dialog>
                    </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr :props="props" >
              <q-td key="name" style="text-align: left">{{'ASIN'}}</q-td>
              <q-td key="parameter" >{{asin || '-'}}</q-td>
            </q-tr>
            <q-tr :props="props">
              <q-td key="name" style="text-align: left">{{'UPC/EAN'}}</q-td>
              <q-td key="parameter" style="max-width: 100px; white-space: normal !important">{{`${(data.row.upcs && data.row.upcs.length>0)?data.row.upcs[0]: '-'} / ${(data.row.eans && data.row.eans.length>0)?data.row.eans[0]: '-'}`}}</q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </q-td>

    <q-td
        key="retailOrder"
        :props="data"
        auto-width
        style="max-width: 25%; text-align: center; width: 25%"
    >
        <div class="q-pl-xl q-pb-lg center" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <q-input v-model="fbmInput" type="number" :class="ifFBMInputColor" input-style="text-align: center" borderless style="font-size: 1.5rem; font-weight: 300;box-shadow: 0px 0px 0px 0px #ccc inset;max-width: 30% ; border-bottom: 1px dotted #ccc; " @update:model-value="handleChange(fbmInput, 'fbm', $event)"/>
        </div>


      <div class="q-pl-xl q-pb-lg center"  style="display: flex; flex-direction: column; justify-content: center; align-items: center ; width: 100% !important;">

        <q-table
            class="my-q-table"
            row-key="id"
            :rows="retailOrderRows"
            :columns="orderCols"
            hide-bottom
            hide-header
            frameless
            binary-state-sort
            style="border: none !important; box-shadow: none !important; background-color: inherit !important; width: 100%"
        >

          <template v-slot:body="props">
            <q-tr :props="props" >
                <q-td key="name" style="text-align: left">
                    <div class="row">
<!--                        <div class="col-3 text-danger q-gutter-md " style="font-size: 2em"-->
<!--                             v-if="data.row.eav && data.row.eav['integrations-amazon-offer-fbm-quantity'] && !data.row.eav['integrations-amazon-offer-sku-fbm']">-->
<!--                            <q-icon name="horizontal_rule" class="bg-red rounded-borders"/>-->
<!--                            <q-tooltip>{{'Missing MSKU'}}</q-tooltip>-->
<!--                        </div>-->
                        <div :class="mskuBackgroundFbm">
                            {{'MSKU'}}
                        </div>
                    </div>


                </q-td>
                <q-td  style="max-width: fit-content; white-space: normal !important">
                    <div class="row">
                        <div class="col">
                            <q-select
                                    v-model="selectedOptionFbm"
                                    :options="optionsFbmArray"
                                    option-value="key"
                                    option-label="value"
                                    :emit-value="true"
                                    menu-cover
                            >
                                <template v-slot:no-option>
                                    <q-item clickable @click="addOptionDialogFBM = true">
                                        <q-item-section>Add Option</q-item-section>
                                        <q-item-section side>
                                            <q-icon name="add" color="primary" />
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:option="scope">
                                    <q-item :key="scope" clickable @click="handleClick('fbm', scope)" class="cursor-pointer">
                                        <q-item-section>{{ scope.label}}</q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:after-options>
                                    <q-item clickable @click="addOptionDialogFBM = true">
                                        <q-item-section>Add Option</q-item-section>
                                        <q-item-section side>
                                            <q-icon name="add" color="primary" />
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                            <q-dialog v-model="addOptionDialogFBM" persistent>
                                <q-card>
                                    <q-card-section>
                                        <q-input v-model="newOptionFBM.key" label="Key" />
                                        <q-input v-model="newOptionFBM.label" label="Label" />
                                    </q-card-section>
                                    <q-card-actions align="right">
                                        <q-btn label="Cancel" @click="addOptionDialogFBM = false" />
                                        <q-btn label="Add" color="primary" :disable="!newOptionFBM.key || !newOptionFBM.label" @click="addNewOptionFBM" />
                                    </q-card-actions>
                                </q-card>
                            </q-dialog>
                        </div>
                    </div>
                </q-td>
            </q-tr>
            <q-tr :props="props" >
              <q-td key="name" style="text-align: left">{{'ASIN'}}</q-td>
              <q-td key="parameter" >{{asin || '-'}}</q-td>
            </q-tr>
            <q-tr :props="props">
              <q-td key="name" style="text-align: left">{{'UPC/EAN'}}</q-td>
              <q-td key="parameter" style="max-width: 100px; white-space: normal !important">{{`${(data.row.upcs && data.row.upcs.length>0)?data.row.upcs[0]: '-'} / ${(data.row.eans && data.row.eans.length>0)?data.row.eans[0]: '-'}`}}</q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </q-td>

    <q-td
        key="inventory"
        :props="data"
        auto-width
        class="col-5 text-center"
    >
      <span>
        <table class="table table-valign-middle">
          <thead>
            <tr>
              <th style="background-color: inherit !important; z-index: 0 ">{{ $t('Status') }}</th>

              <th style="background-color: inherit !important; z-index: 0">{{ $t('Quantity') }}</th>

              <th style="background-color: inherit !important; z-index: 0">{{ $t('Warehouse') }}</th>

            </tr>
          </thead>

          <tbody style="background-color: inherit !important; ">
            <tr>
              <td class="bg-white" style="background-color: inherit !important; ">
                <label :class="`q-py-xs q-px-sm rounded bg-primary`">
                  {{ 'Received' }}
                </label>
              </td>
              <td>{{data.row.quantityReceived}}</td>
              <td>{{'-'}}</td>
            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-secondary`">
                  {{'Shipped'}}
                </label>
              </td>
              <td>{{shipped}}</td>
              <td>{{shippedWarehouse}}</td>
            </tr>
            <tr>
              <td >
                <label :class="`q-py-xs q-px-sm rounded bg-warning`">
                  {{'Difference'}}
                </label>
              </td>
              <td>{{data.row.quantityReceived - shipped}}</td>
              <td>{{'-'}}</td>
            </tr>
            <tr>
              <td >
                <label :class="`q-py-xs q-px-sm rounded bg-positive`">
                  {{'On hand'}}
                </label>
              </td>
              <td>{{onHand}}</td>
              <td>{{onHandWarehouse}}</td>
            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-info`">
                     {{'New'}}
                </label>
              </td>
              <td>{{ newState }}</td>
              <td>{{newStateWarehouse}}</td>
            </tr>
            <tr >
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-green-7`">
                  {{'Blocked'}}
                </label>
              </td>
              <td>{{blocked}}</td>
              <td>{{blockedWarehouse}}</td>
            </tr>
            <tr >
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-danger`">
                  {{'Booked'}}
                </label>
               </td>
              <td>{{booked}}</td>
              <td>{{bookedWarehouse}}</td>
            </tr>
          </tbody>
        </table>
      </span>
    </q-td>

  </q-tr>

  <q-tr v-if="bundleToggle">
    <q-td
        key="number"
        colspan="7"
    >
      <q-table
          class="sticky-header-table q-mt-md"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="bundleItems"
          :columns="bundleCols"
          :loading="isLoading"
          hide-bottom
          virtual-scroll
          binary-state-sort
          @request="onRequest"
      >
        <template
            v-slot:body="props"
            class="clickable"
        >
          <products-matching-bundle-table
              :data="props"
              :product="data.row"
              :column="props.column"
          />
        </template>

      </q-table>
    </q-td>
  </q-tr>
</template>

<script>
import { mapMutations , mapGetters } from 'vuex'

// Components
import ProductsMatchingBundleTable from './ProductsMatchingBundleTable'
import {buildQuery} from "@/apps/app/utils/query-utils";
import Template from "@/apps/app/pages/notifications/Template.vue";

export default {
    name: 'ItemsRow',
    emits: ['check', 'dblclick', 'open'],
    components: {
        Template,
        ProductsMatchingBundleTable
    },
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        },
        data: {
            type: Object,
            required: true,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            bundleToggle: false,
            infoToggle: true,
            bundleItems: [],
            isLoading: false,
            onHand: 0,
            onHandWarehouse: '-',
            newState: 0,
            newStateWarehouse: '-',
            booked: 0,
            bookedWarehouse: '-',
            blocked: 0,
            blockedWarehouse: '-',
            shipped: 0,
            shippedWarehouse: '-',
            bundleCols: [
                {
                    label: '#',
                    name: 'number',
                    align: 'left'
                },
                {
                    label: this.$t('Image'),
                    name: 'image',
                    align: 'center'
                },
                {
                    label: this.$t('Component details'),
                    name: 'component',
                    align: 'left'
                },
                {
                    label: this.$t('Quantity in Bundle'),
                    name: 'count',
                    align: 'left'
                },
                {
                    label: this.$t('Parameters'),
                    name: 'parameters',
                    align: 'left'
                },
                {
                    label: this.$t('Inventory'),
                    name: 'inventory',
                    align: 'left'
                },
            ],
            orderCols: [
                {
                    name: 'name',
                    label: this.$t('Name'),
                    align: 'left',
                    field: 'name'
                },
                {
                    name: 'parameter',
                    label: this.$t('Parameter'),
                    align: 'left',
                    field: 'parameter'
                }
            ],
            orderRows: [
                {
                    id: 1,
                    MSKU: this.msku,
                    ASIN: this.asin,
                },
            ],
            retailOrderRows: [
                {
                    id: 1,
                    MSKU: this.mskuFBM,
                    Asin: this.asin
                }
            ],
            itemStates: {
                new: 'grey-3',
                blocked: 'danger',
                booked: 'danger',
                deleted: 'danger',
                shipped: 'green text-white',
                normal: 'success',
                expected: 'green text-white',
                missing: 'grey'
            },
            marketplace: "ATVPDKIKX0DER",
            asin: null,
            msku: null,
            optionsFbaArray: [],
            selectedOption: "",
            newOption: {key: "", label: ""},
            addOptionDialog: false,
            optionsFbmArray: [],
            selectedOptionFbm: "",
            newOptionFBM: {key: "", label: ""},
            addOptionDialogFBM: false,
            mskuFBM: null,
            options: null,
            dropdownOpen: false,
            dropdownFBMOpen: false,
            shop: null,
            fbmInput: 0,
            fbaInput: 0
        }
    },
    beforeMount() {
        if (this.data.row.eav) {
            this.marketplace = this.data.row.eav['integrations-amazon-offer-marketplace'] ? this.data.row.eav['integrations-amazon-offer-marketplace'] : "ATVPDKIKX0DER"
            this.getASIN(this.marketplace)
            this.getMsku(this.marketplace)
            this.getMskuFBM(this.marketplace)
        }
        if (this.data.row?._embedded?.shop) {
            this.shop = this.data.row._embedded.shop.id
        }
        if (this.data.row.eav && this.data.row.eav['integrations-amazon-offer-fbm-quantity']) {
            this.fbmInput = this.data.row.eav['integrations-amazon-offer-fbm-quantity']
        }
        if (this.data.row.eav && this.data.row.eav['integrations-amazon-offer-fba-quantity']) {
            this.fbaInput = this.data.row.eav['integrations-amazon-offer-fba-quantity']
        }
    },
    mounted() {

        if (this.data.row.items) {
            let booked = this.data.row.items.filter(e => e.state === 'booked')
            let blocked = this.data.row.items.filter(e => e.state === 'blocked')
            let onHand = this.data.row.items.filter(e=> e.state === 'normal')
            let newState = this.data.row.items.filter(e=> e.state === 'new')
            let shipped = this.data.row.items.filter(e=> e.state === 'shipped')
            if (booked.length > 0) {
                this.booked = booked[0].count
                this.bookedWarehouse = booked[0].warehouse
            }
            if (blocked.length > 0) {
                this.blocked = blocked[0].count
                this.blockedWarehouse = blocked[0].warehouse
            }
            if (onHand.length > 0) {
                this.onHand = onHand[0].count
                this.onHandWarehouse = onHand[0].warehouse
            }
            if (newState.length > 0) {
                this.newState = newState[0].count
                this.newStateWarehouse = newState[0].warehouse
            }
            if (shipped.length > 0) {
                this.shipped = shipped[0].count
                this.shippedWarehouse = shipped[0].warehouse
            }
        }
    },
    computed: {
        ...mapGetters([
            'appOptions',
        ]),
        mskuBackgroundFba() {
            if(this.data.row.eav && this.data.row.eav['integrations-amazon-offer-fba-quantity'] && !this.data.row.eav['integrations-amazon-offer-sku-fba']) {
                return 'q-py-xs q-px-sm rounded bg-warning'
            }
            return ''
        },
        mskuBackgroundFbm() {
            if(this.data.row.eav && this.data.row.eav['integrations-amazon-offer-fbm-quantity'] && !this.data.row.eav['integrations-amazon-offer-sku-fbm']) {
                return 'q-py-xs q-px-sm rounded bg-warning'
            }
            return ''
        },
        ifFBAInputColor() {
            if (this.fbaInput) {
                return ''
            }
        },
        ifFBAinputEmtpy() {
            if (this.fbaInput) {
                return this.fbaInput
            } else {
                return 'none'
            }
        },
        ifFBMInputColor() {
            if (this.fbmInput) {
                return ''
            }
        },
        ifFBMinputEmtpy() {
            if (this.fbmInput) {
                return this.fbmInput
            } else {
                return 'none'
            }
        },
        isItems() {
            let result = []
            if (this.data.row.items) {
                result = this.data.row.items
                return result.sort((a, b) => {
                    let fa = a.state.toLowerCase(),
                        fb = b.state.toLowerCase();
                    if (fa[0] < fb[0]) {
                        return -1;
                    }
                    if (fa[0] > fb[0]) {
                        return 1;
                    }
                    return 0;
                })
            }
            return result
        },
        chipColor() {
            if (this.data.row.type === 'bundle') {
                return 'accent'
            }
            return 'orange-6'
        },
        chipSourceColor() {
            if (this.data.row.eav && this.data.row.eav['integrations-source-type']) {
                return 'grey-8'
            }
            return 'red-6'
        },
        schema() {
            const value = this.itemStates[0].buttons.find(({id}) => id === this.data.row.state) || {
                id: 'normal',
                title: 'Active'
            }
            const option = {
                id: 'statuses',
                type: 'super-dropdown',
                variant: 'link',
                color: value.color || 'default',
                disabledLabels: true,
                editableFields: false,
                onClick: () => {

                },
                value,
                options: this.itemStates
            }

            return option
        }
    },
    methods: {
        ...mapMutations([
            'upsertOffer'
        ]),
        handleShowInfo() {
            this.$emit('open', this.data.row)
        },
        handleChange(test, key, value) {
            if (key === 'fba' && value >= 0) {
                this.$service.offer.save({eav: {'integrations-amazon-offer-fba-quantity': value}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.fbaInput = value
                        this.upsertOffer(item)
                    })
            } else if (key === 'fbm' && value >= 0) {
                this.$service.offer.save({eav: {'integrations-amazon-offer-fbm-quantity': value}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.fbmInput = value
                        this.upsertOffer(item)
                    })
            }
        },
        getMskuFBM(marketplace) {
            if (this.data?.row?.eav['integrations-amazon-offer-sku-fbm'] && marketplace) {
                this.mskuFBM = this.data.row.eav['integrations-amazon-offer-sku-fbm'][marketplace]
                if (Array.isArray(this.mskuFBM) && this.mskuFBM) {

                    this.optionsFbmArray = this.mskuFBM.map(option => {
                        const key = Object.keys(option)[0];
                        const value = option[key];
                        return `${key}: ${value}`;
                    })
                }
            }
            if (this.data?.row?.eav['integrations-amazon-offer-sku-fbm'] && marketplace) {
                const choosenMsku = this.data.row.eav['integrations-amazon-offer-sku-fbm'][marketplace]
                if (choosenMsku) {
                    this.selectedOptionFbm = [choosenMsku].map(option => {
                        const key = Object.keys(option)[0];
                        const value = option[key];
                        return `${key}: ${value}`;
                    })
                }
            }
        },
        getMsku(marketplace) {
            if (this.data?.row?.eav['integrations-amazon-offer-sku-fba-list'] && marketplace) {
                this.msku = this.data.row.eav['integrations-amazon-offer-sku-fba-list'][marketplace]
                if (Array.isArray(this.msku) && this.msku) {

                    this.optionsFbaArray = this.msku.map(option => {
                        const key = Object.keys(option)[0];
                        const value = option[key];
                        return `${key}: ${value}`;
                    })
                }

            }
            if (this.data?.row?.eav['integrations-amazon-offer-sku-fba'] && marketplace) {
                const choosenMsku = this.data.row.eav['integrations-amazon-offer-sku-fba'][marketplace]
                if (choosenMsku) {
                    this.selectedOption = [choosenMsku].map(option => {
                        const key = Object.keys(option)[0];
                        const value = option[key];
                        return `${key}: ${value}`;
                    })
                }
            }
        },
        getASIN(marketplace) {
            if (this.data?.row?.eav['integrations-amazon-offer-asin'] && marketplace) {
                this.asin = this.data.row.eav['integrations-amazon-offer-asin'][marketplace]
            }
        },
        imageCheck(image) {
            if (!image) {
                return ''
            }
            if (image.includes('url=')) {
                const realImage = image.split('url=')
                return `${realImage[1]}`
            } else {
                return `${image}`
            }
        },
        handleBundleToggle(event) {
            if (!event.target.innerText.includes('arrow')) {
                return
            }
            this.bundleToggle = !this.bundleToggle
            if (this.bundleToggle) {
                this.isLoading = true
                this.onRequest()
            }
        },
        handleInfoToggle() {
            this.infoToggle = !this.infoToggle
        },
        onRowDblClick() {
            this.$emit('dblclick', this.data.row)
        },
        handleCheck() {
            this.$emit('check', this.data.row)
        },
        onImageLoadFailure(e) {
            e.target.src = 'assets/img/fallback-image/package.png'
        },
        handleClick(field, key) {
            if (field === 'fba') {

                const test = key.label.split(':')
                this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fba': {[this.marketplace]: {[test[0]]: test[1]}}}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.upsertOffer(item)
                        this.getMsku(this.marketplace)
                        this.$router.go()
                    })
            } else {

                const test = key.label.split(':')
                this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fbm': {[this.marketplace]: {[test[0]]: test[1]}}}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.upsertOffer(item)
                        this.getMskuFBM(this.marketplace)
                        this.$router.go()
                    })
            }
        },
        onRequest(data = {}) {
            this.pagination = data.pagination || {}
            const query = buildQuery(this.pagination)
            let products = []
            if (!Array.isArray(this.data.row.eav['products-offer-bundle'])) {
                products.push(this.data.row.eav['products-offer-bundle'])
            } else {
                products = this.data.row.eav['products-offer-bundle']
            }
            let simpleProducts = products.reduce((acc, el) => {
                acc.push(el.productOffer)
                return acc
            }, [])
            query.filter = [
                {type: 'in', field: 'id', values: simpleProducts},
                {type: 'eq', field: 'shop', value: this.shop}
            ]

            this.$service.offerInventory.getAll(query)
                .then(({items, page, totalItems}) => {
                    this.pagination = {
                        ...this.pagination,
                        page,
                        rowsNumber: totalItems
                    }
                    if (Array.isArray(items)) {
                        this.bundleItems = items
                    } else {
                        this.bundleItems = [items]
                    }

                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        addNewOption() {

            this.optionsFbaArray.push(`${this.newOption.key}:${this.newOption.label}`)
            let updatedList = []
            if (Array.isArray(this.msku) && this.msku) {
                updatedList = [...this.msku, {[this.newOption.key]: this.newOption.label}]
                this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fba-list': {[this.marketplace]: updatedList}}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.upsertOffer(item)
                    })
            } else {
                this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fba-list': {[this.marketplace]: [{[this.newOption.key]: this.newOption.label}]}}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.upsertOffer(item)
                    })
            }
            this.newOption.key = "";
            this.newOption.label = "";
            this.addOptionDialog = false;
        },
        addNewOptionFBM() {

            this.optionsFbmArray.push(`${this.newOptionFBM.key}:${this.newOptionFBM.label}`)
            let updatedList = []
            if (Array.isArray(this.mskuFBM) && this.mskuFBM) {
                updatedList = [...this.mskuFBM, {[this.newOptionFBM.key]: this.newOptionFBM.label}]
                this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fbm-list': {[this.marketplace]: updatedList}}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.upsertOffer(item)
                        this.getMsku(this.marketplace)
                        this.getMskuFBM(this.marketplace)
                    })
            } else {
                this.$service.offer.save({eav: {'integrations-amazon-offer-sku-fbm-list': {[this.marketplace]: [{[this.newOptionFBM.key]: this.newOptionFBM.label}]}}}, this.data.row.id, this.shop, {})
                    .then(item => {
                        this.upsertOffer(item)
                        this.getMsku(this.marketplace)
                        this.getMskuFBM(this.marketplace)
                    })
            }
            this.newOptionFBM.key = "";
            this.newOptionFBM.label = "";
            this.addOptionDialogFBM = false;
        },
    }
}
</script>

<style scoped>
.my-q-table {
  background-color: inherit;
}
</style>