<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 90vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center text-capitalize">
          {{ $t(title) }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section
          v-if="items.length > 0 && !isLoading"
          class="text-center text-subtitle1 q-pa-none text-bold"
      >
        {{
          $t('Product') + ': ' + items[0]._embedded.productOffer.name + ' (' + items[0]._embedded.productOffer.id + ')'
        }}
      </q-card-section>

      <q-card-section
          v-if="options.description"
          class="text-center text-subtitle1 q-pa-none"
      >
        {{ options.description }}
      </q-card-section>

      <q-card-section>
        <q-table
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isLoading"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <q-td
                  key="reserve"
                  :props="props"
              >
                <div v-if="props.row._embedded.reserve">
                  {{ props.row._embedded.reserve.id }}

                  <q-badge
                      class="text-dark"
                      :color="stateColors[props.row._embedded.reserve.state]"
                      :label="$t(props.row._embedded.reserve.state)"
                  />
                </div>

                <div v-else>
                  {{ $t('No reserve') }}
                </div>
              </q-td>

              <q-td
                  key="state"
                  :props="props"
              >
                <div v-if="props.row.state">
                  <q-badge
                      class="text-dark"
                      :color="stateColors[props.row.state]"
                      :label="$t(props.row.state)"
                  />
                </div>
              </q-td>

              <q-td
                  key="place"
                  :props="props"
              >
                <span v-if="props.row._embedded?.place">
                  <new-picking-place
                      :data="props.row._embedded.place"
                      :type="props.row._embedded.place.type"
                      badge
                  />
                </span>

                <span v-else>-</span>
              </q-td>

              <q-td
                  key="warehouse"
                  :props="props"
              >
                <div v-if="props.row._embedded.warehouse">
                  {{ `${props.row._embedded.warehouse.name} (${props.row._embedded.warehouse.id})` }}
                </div>

                <div v-else>
                  {{ $t('No') }}
                </div>
              </q-td>

              <q-td
                  key="count"
                  :props="props"
              >
                {{ props.row.count }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Utils
import { buildQuery } from '../../utils/query-utils'
import NewPickingPlace from '@/apps/app/components/picking/new-picking-place.vue'

export default {
  name: 'ItemsInfoModal',
  components: { NewPickingPlace },
  data () {
    return {
      titles: {
        booked: 'Booked',
        blocked: 'Blocked',
        normal: 'On hand'
      },
      stateColors: {
        new: 'grey-4',
        picking: 'teal-12',
        cancelled: 'danger'
      },
      columns: [
        {
          label: this.$t('Reserve'),
          name: 'reserve',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Storage location'),
          name: 'place',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left'
        },
        {
          label: this.$t('Count'),
          name: 'count',
          align: 'left'
        }
      ],
      isOpen: false,
      isLoading: false,
      isOneOffer: true,
      items: [],
      params: {},
      group: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      filter: '',
      options: {},
      filters: []
    }
  },
  computed: {
    title () {
      if (this.params.state) {
        return this.titles[this.params.state] || this.params.state
      }

      if (this.group.find(x => x.field === 'place')) {
        return 'Locations'
      }

      return 'Items Info'
    }
  },
  methods: {
    open (params, group = [], options = {}) {
      this.isOpen = true
      this.params = params
      this.group = group
      this.options = options

      let filter = Object.entries(params).map(([field, value]) => {
        if (Array.isArray(value)) {
          return { type: 'in', field, values: value }
        }

        return { type: 'eq', field, value }
      })

      if (options.filter) {
        filter = [...filter, ...options.filter]
      }

      this.filters = filter

      return this.onRequest({ pagination: { page: 1, rowsPerPage: 25 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters
      query.group = this.group

      this.isLoading = true

      return this.$service.storageItemEntity.getAll(query)
          .then(({ items, page, totalItems }) => {
            this.items = items

            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.isOneOffer = this.items.length <= 0 || !this.items.find(x => this.items[0]._embedded.productOffer.id !== x._embedded.productOffer.id)
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    close () {
      this.isOpen = false
    },
    showPlacePath (row) {
      if (row._embedded.place.path) {
        if (Array.isArray(row._embedded.place.path)) {
          return row._embedded.place.path.join('-')
        }
        return JSON.parse(row._embedded.place.path).join('-')
      } else {
        return 0
      }
    }
  }
}
</script>
