<template>
  <q-card class="cursor-pointer" :class="{'bg-amber-3': item?.id }"
          :style="{'border': selectedProduct?._embedded?.productOffer?.id === product?._embedded?.productOffer?.id && product.selected ? '2px solid red' : '', 'padding': !product.selected ? '2px' : '0'}">

    <q-card-section class="text-caption wrap q-py-none text-center" style="align-content: center" :class="bg">
      <div class="row">
        <div class="col-auto d-flex align-items-center justify-content-start">
          <q-icon name="delete" class="q-mr-sm" size="sm" @click="handleRemove"/>
        </div>
        <div class="col d-flex align-items-center justify-content-center">
          <strong class="text-uppercase" style="font-size: 0.7rem;"> {{
              $t(product._embedded?.productOffer?.type || '')
            }} </strong>
        </div>
        <div class="col-auto d-flex align-items-center justify-content-end">
          <q-icon name="check_box" class="q-mr-sm" size="sm" @click="handleSelect"/>
        </div>
      </div>
    </q-card-section>
    <div class="row">
      <q-card-section>
        <q-img
            :src="product._embedded?.productOffer?.image || fallbackImage"
            :alt="product._embedded?.productOffer?.name"
            style="height: 50px; width: 50px; object-fit: contain; max-height: 100%"
            fit="contain"
            basic
            @error="onImageLoadFailure"
        />
      </q-card-section>

      <q-card-section class="col">
        <div
            class="text-subtitle2"
            style="max-height: 36px; overflow: auto;"
        >
          {{ product._embedded?.productOffer?.name }}
        </div>

        <div class="text-caption">
          {{ $t('Id') + ': ' + product._embedded?.productOffer?.id }}
        </div>

      </q-card-section>
    </div>

    <div v-if="hasBarcode" class="q-pb-xs q-px-xs">
      <q-select
          :model-value="product.barcode"
          standout="bg-teal text-white"
          :options="barcodeOptions"
          @filter="filterBarcodes"
          @focus="handleFocus"
          @blur="handleFocusOut"
          :label="$t('Barcode')"
          :emit-value="true"
          :clear-input="true"
          ref="barcodeSelect"
          menu-cover
          dense
          use-input
          hide-dropdown-icon
          clearable
          fill-input
          hide-selected
          @clear="handleRemoveBarcode"
          new-value-mode="add"
          @new-value="handleNewBarcode"
      >
        <template v-slot:option="scope">
          <q-item :key="scope" v-close-popup clickable @click="handleSku(scope)" class="cursor-pointer">
            <q-item-section>
              {{ scope.label }}
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <div v-if="hasBarcode" class="q-pb-xs q-px-xs">
      <q-input
          :model-value="product.trackingNumber"
          standout="bg-teal text-white"
          type="text"
          class="date-field q-mr-sm col"
          :label="$t('Tracking number')"
          dense
          @update:model-value="handleTrackingNumber"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />
    </div>

    <div v-if="isBatch" class="row items-center justify-center q-pb-xs q-px-xs">
      <q-input
          :model-value="product.expires"
          standout="bg-teal text-white"
          type="date"
          class="date-field q-mr-sm col"
          :label="$t('Expires')"
          dense
          @update:model-value="handleExpires"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />

      <q-input
          :model-value="product.batch"
          standout="bg-teal text-white"
          type="text"
          class="col"
          :label="$t('Batch')"
          dense
          @update:model-value="handleBatch"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />
    </div>

    <div v-if="!isSelect" class="row items-center justify-center q-pb-xs q-px-xs">
      <q-input
          :model-value="product.purchasingPrice"
          standout="bg-teal text-white"
          type="number"
          class="q-mr-sm col"
          :label="$t('Purchasing price')"
          dense
          @update:model-value="handlePurchasingPrice"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />

      <q-input
          :model-value="product.price"
          standout="bg-teal text-white"
          type="number"
          class="col"
          :label="$t('Price')"
          dense
          @update:model-value="handlePrice"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />
    </div>

    <div v-if="hasComments" class="q-pb-xs q-px-xs">
      <q-input
          :model-value="product.comment"
          standout="bg-teal text-white"
          :label="$t('Comment')"
          dense
          @update:model-value="handleComment"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />
      <!--        100277-->
    </div>
    <div v-if="!isSelect && !(isUnique || []).includes(product.type)"
         class="row items-center justify-center q-pb-xs q-px-xs">
      <q-btn
          color="negative"
          text-color="white"
          size="sm"
          icon="remove"
          :disable="product.count <= 1"
          no-caps
          unelevated
          @click="handleCount(Number(product.count) - 1)"
      />

      <q-input
          :model-value="product.count"
          standout="bg-teal text-white"
          type="number"
          class="q-mx-xs col"
          :label="$t('Quantity')"
          dense
          @change="handleCount"
          @focus="handleFocus"
          @blur="handleFocusOut"
      />

      <q-btn
          color="positive"
          text-color="white"
          size="sm"
          icon="add"
          no-caps
          unelevated
          @click="handleCount(Number(product.count) + 1)"
      />
    </div>
  </q-card>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Utils
import _ from 'lodash'
import Template from '@/apps/app/pages/notifications/Template.vue'

export default {
  name: 'ProductGridRow',
  components: { Template },
  emits: ['change', 'focus', 'blur', 'select', 'delete'],
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    hasBarcode: {
      type: Boolean,
      default () {
        return false
      }
    },
    isSelect: {
      type: Boolean,
      default () {
        return false
      }
    },
    isUnique: {
      type: Array,
      default () {
        return []
      }
    },
    isBatch: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasComments: {
      type: Boolean,
      default () {
        return false
      }
    },
    row: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    selectedProduct: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    row () {
      this.loadProduct()
    }
  },
  data () {
    return {
      product: {},
      barcodeOptions: [],
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  mounted () {
    this.loadProduct()
  },
  computed: {
    bg () {
      if (this.product._embedded?.productOffer?.type === 'simple') {
        return 'bg-cyan-6'
      } else if (this.product._embedded?.productOffer?.type === 'bundle') {
        return 'bg-purple-4'
      } else {
        return 'bg-yellow-6'
      }
    },
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleFocus (e) {
      this.$emit('focus', e)
    },
    handleFocusOut (e) {
      this.$emit('blur', e)
    },
    handleTrackingNumber (value) {
      this.product.trackingNumber = value
      this.$emit('change', { ...this.product })
    },
    handleExpires (value) {
      this.product.expires = value
      this.$emit('change', { ...this.product })
    },
    handleBatch (value) {
      this.product.batch = value
      this.$emit('change', { ...this.product })
    },
    handleRemoveBarcode () {
      this.product.barcode = null

      this.$emit('change', { ...this.product })
    },
    handleNewBarcode (value) {
      this.product.barcode = value

      if (this.product?._embedded?.productOffer) {
        let barcodes = []
        if (this.product?._embedded?.productOffer?.barcodes) {
          barcodes = Object.values(this.product._embedded.productOffer.barcodes)
        }

        barcodes.push(value)

        this.product._embedded.productOffer.barcodes = barcodes
      }

      const barcodeSelect = this.$refs.barcodeSelect
      barcodeSelect.hidePopup()

      this.$emit('change', { ...this.product })
    },
    handleSku (value) {
      this.product.barcode = value.opt

      const barcodeSelect = this.$refs.barcodeSelect
      barcodeSelect.hidePopup()

      this.$emit('change', { ...this.product })
    },
    filterBarcodes (val, update) {
      update(() => {
        const needle = val
        this.barcodeOptions = Object.values(this.product?._embedded?.productOffer?.barcodes).filter(v => v.toLowerCase().indexOf(needle) > -1)
      })
    },
    handlePurchasingPrice (value) {
      this.product.purchasingPrice = value
      this.$emit('change', { ...this.product })
    },
    handlePrice (value) {
      this.product.price = value
      this.$emit('change', { ...this.product })
    },
    handleComment (value) {
      this.product.comment = value
      this.$emit('change', { ...this.product })
    },
    handleCount (value) {
      if (value <= 0) {
        this.addErrorNotification('Minimum quantity is 1.')
        value = 1
      }

      this.product.count = value
      this.$emit('change', { ...this.product })
    },
    handleRemove () {
      this.$emit('delete', this.product)
    },
    loadProduct () {
      this.product = _.cloneDeep(this.row)
      this.barcodeOptions = this.product?._embedded?.productOffer?.barcodes
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleSelect () {
      if (this.selectedProduct?._embedded?.productOffer.id === this.product?._embedded?.productOffer.id) {
        this.product.selected = false
        this.$emit('select', null)
        this.$emit('change', { ...this.product })
      } else {
        this.product.selected = true
        this.$emit('select', this.product)
        this.$emit('change', { ...this.product })
      }

    }
  }
}
</script>
