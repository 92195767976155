<template>
    <q-dialog v-model="isOpen">
        <q-card style="min-width: 50vw">
            <q-card-section class="row items-center">
                <div class="text-h6 text-center">
                    {{ $t("You`ve selected products from ") }}
                </div>

                <q-space />

                <q-btn
                        color="transparent"
                        text-color="dark"
                        size="sm"
                        icon="close"
                        no-caps
                        unelevated
                        @click="hide"
                />
            </q-card-section>

            <q-card-section style="margin-top: -30px; margin-bottom: -30px;">
                <div v-if="shops.length > 0" class="row">
                    <q-tabs v-model='selectedShop' v-for="item in shops" class="col">
                        <q-tab :label="item.name" :key="item.id" @click="selectShop(item)" class="col"/>
                    </q-tabs>
                </div>
            </q-card-section>

            <q-card-section v-if="selectedItems.length > 0">
                <q-list bordered separator>
                    <q-item v-for="item in selectedItems" :key="item.id" v-ripple>
                        <q-item-section avatar>
                            <img
                                    style="max-height: 50px; width: 60px; object-fit: contain"
                                    :src="item.image || fallbackImage"
                                    @error="onImageLoadFailure"
                            />
                        </q-item-section>

                        <q-item-section>
                            {{ `${item.name} (${item.id})` }}
                        </q-item-section>

                        <q-input
                                standout="bg-teal text-white"
                                type="number"
                                :model-value="item.count"
                                :label="$t('Quantity')"
                                @update:model-value="updateItem(item.id, 'count', $event)"
                        />

                        <q-item-section>
                            {{item._embedded.shop.name}}
                        </q-item-section>

                        <q-item-section style="max-width: 80px" class="q-pl-sm">
                            <q-btn
                                    color="negative"
                                    size="sm"
                                    :label="$t('Delete')"
                                    @click="handleDelete(item.id)"
                            />
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-card-section>

            <q-card-section v-else class="q-pa-lg text-center text-subtitle1">
                {{ $t("No products") }}
            </q-card-section>

            <q-card-section class="text-center">
                <q-btn
                        color="light-blue-9"
                        text-color="white"
                        class="q-mr-sm"
                        :label="$t('Add')"
                        :disable="items.length <= 0 || shops.length > 1"
                        @click="save"
                />
                <q-tooltip v-if="shops.length > 1" class="bg-red"> Products should be from one shop</q-tooltip>

                <q-btn
                        color="dark"
                        text-color="white"
                        :label="$t('Discard')"
                        @click="hide"
                />

            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    name: 'OrderProductsMovementsModal',
    props: {
        warehouse: {
            type: Object,
            default () {
                return null
            }
        }
    },
    data () {
        return {
            fallbackImage: 'assets/img/fallback-image/package.png',
            isOpen: false,
            orderType: 'wholesale',
            items: [],
            shops: [],
            selectedShop: null,
            selectedItems: this.items || [],
        }
    },
    computed: {
        ...mapGetters([
            'order'
        ])
    },
    updated() {
        if (Array.isArray(this.items)) {
            this.items.forEach(e=>{
                if( e._embedded.shop) {
                    if(this.shops.some(el=>el.id === e._embedded.shop.id)) {
                        return
                    } else {
                        this.shops.push({id: e._embedded.shop.id , name: e._embedded.shop.name})
                    }
                }
            })
        }
    },
    watch:{
        items (value) {
            this.selectedItems = value
            this.shops = []
            this.items.forEach(e=>{
                if( e._embedded.shop) {
                    if(this.shops.some(el=>el.id === e._embedded.shop.id)) {
                        return
                    } else {
                        this.shops.push({id: e._embedded.shop.id , name: e._embedded.shop.name})
                    }
                }
            })
        }
    },
    methods: {
        ...mapMutations([
            'addErrorNotification',
            'setNewOrder',
            'setOrderProducts',
            'setNewDeliveryRequest',
            'updateOrder',
            'setNewAddress'
        ]),
        selectShop(item) {
            this.selectedShop = item;
            this.selectedItems= this.items.filter(e=>e._embedded.shop.id === item.id)
        },
        handleDeleteAll () {
            this.items = []
        },
        show (items) {
            this.items = [...items]
            this.isOpen = true
        },
        hide () {
            this.isOpen = false
        },
        save () {
            if (!this.orderType) {
                this.addErrorNotification('Please select order type!')
                return
            }

            this.setNewOrder({ type: this.orderType })
            this.updateOrder({
                _embedded: {
                    ...this.order._embedded,
                    shop: this.items[0]._embedded.shop,
                    warehouse: this.warehouse
                }
            })

            const products = this.items.map(productOffer => {
                return {
                    count: productOffer.eav['integrations-amazon-offer-fba-quantity'] || productOffer.quantityAllocated,
                    price: productOffer.price,
                    _embedded: {
                        shop: productOffer._embedded.shop,
                        productOffer
                    }
                }
            })
            this.setOrderProducts(products)
            this.setNewAddress()
            this.setNewDeliveryRequest()
            this.$router.push(`/outbound/orders/entity/${this.orderType}?hasInit=true`)
        },
        handleDelete (id) {

            this.items = this.items.filter(x => x.id !== id)
        },
        updateItem (id, field, event) {
            this.items = this.items.map(item => {
                if (item.id !== id) {
                    return item
                }

                return { ...item, [field]: event }
            })
        },
        onImageLoadFailure (e) {
            e.target.src = 'assets/img/fallback-image/package.png'
        }
    }
}
</script>
