<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 60vw;" class="q-pa-none">
      <product
          :options="options"
          :mode="'modal'"
          @close="close"
          @submit="handleSubmit"
      />
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapActions, mapMutations } from 'vuex'

// Components
import Product from '../../pages/wms/Product.vue'

export default {
  name: 'OfferModal',
  emits: ['submit'],
  components: {
    Product
  },
  data () {
    return {
      isOpen: false,
      options: {}
    }
  },
  methods: {
    ...mapMutations([
      'setOffer'
    ]),
    ...mapActions([
      'loadOffer'
    ]),
    handleSubmit (offer) {
      this.$emit('submit', offer)
      this.close()
    },
    close () {
      this.isOpen = false
    },
    open (offer, options = {}) {
      this.options = options

      if (!offer) {
        this.isOpen = true
        return
      }

      if (offer._embedded) {
        if (typeof offer.barcodes === 'string' && offer.barcodes.startsWith('[') && offer.barcodes.endsWith(']')) {
          offer.barcodes = JSON.parse(offer.barcodes)
        }

        this.setOffer(offer)
        this.isOpen = true
      } else {
        this.loadOffer({ id: offer.id })
            .then(() => {
              this.isOpen = true
            })
      }
    }
  }
}
</script>
