<template>
  <div
    v-if="isLoading"
    class="bg-shadow shadow"
    style="min-height: 40px;"
  />

  <q-toolbar
    v-else
    ref="toolbar"
    class="shadow items-center row"
  >
    <div
      v-if="actionsModel"
      class="row full-width items-center col"
    >
      <div
        v-for="(section, index) in actionsModel"
        :key="index"
        :class="section.className"
        :style="section.style"
      >
        <div
          v-for="(option, x) in section.options"
          :key="x"
          :class="option.wrapperClassName || ''"
          :style="option.wrapperStyle || ''"
        >
          <component
            :is="components[option.type]"
            v-if="typeof option.if !== 'boolean' || option.if"
            v-bind="{ option, hasIcon }"
          />
        </div>
      </div>
    </div>

    <div
      v-if="$q.screen.width > 700 && isPin"
      class="q-pa-sm"
    >
      <q-btn
        :color="note.color"
        size="sm"
        :icon="note.icon"
        @click="handleNote"
        no-caps
      />
    </div>
  </q-toolbar>
</template>

<script>
// Components
import SuperDropdown from '../super-dropdown/SuperDropdown'
import ActionButton from './ActionButton.vue'
import ActionDropdown from './ActionDropdown.vue'
import ActionSuperDropdown from './ActionSuperDropdown.vue'
import ActionText from './ActionText.vue'
import ActionLazyDropdown from './ActionLazyDropdown.vue'
import ActionPortal from './ActionPortal.vue'

// Utils
import { doCopy } from './../../utils/copy-utils'

// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Quasar
import { debounce } from 'quasar'

export default {
  name: 'ActionHeader',
  components: {
    SuperDropdown,
    ActionDropdown,
    ActionSuperDropdown,
    ActionText,
    ActionButton,
    ActionLazyDropdown,
    ActionPortal
  },
  props: {
    page: {
      type: Object,
      default () {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    actionsModel: {
      type: Array,
      default () {
        return []
      }
    },
    headerTitle: {
      type: String,
      default () {
        return ''
      }
    },
    isPin: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      components: {
        'super-dropdown': 'action-super-dropdown',
        text: 'action-text',
        dropdown: 'action-dropdown',
        'lazy-dropdown': 'action-lazy-dropdown',
        button: 'action-button',
        portal: 'action-portal'
      },
      hasIcon: true
    }
  },
  computed: {
    ...mapGetters([
      'routeShortcuts'
    ]),
    note () {
      if (this.routeShortcuts.find(({ route }) => route === this.$route.fullPath)) {
        return {
          color: 'positive',
          label: 'Unpin',
          icon: 'push_pin'
        }
      }
      return {
        color: 'dark',
        label: 'Pin',
        icon: 'push_pin'
      }
    }
  },
  mounted () {
    this.handleResize = this.handleResize.bind(this)
    this.handleResize = debounce(this.handleResize, 500)

    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations([
      'addRouteToShortcuts',
      'removeRouteToShortcuts'
    ]),
    handleResize () {
      this.hasIcon = this.$refs.toolbar
        ? this.$refs.toolbar.$el.clientWidth > 1000
        : window.innerWidth > 1000
    },
    handleNote () {
      const action = this.routeShortcuts.find(({ route }) => route === this.$route.fullPath)
        ? 'removeRouteToShortcuts'
        : 'addRouteToShortcuts'

      const data = {
        route: this.$route.fullPath,
        name: this.page.id,
        description: this.page.name
      }

      this[action](data)
    },
    doCopy (content) {
      return doCopy(content)
    }
  }
}
</script>
