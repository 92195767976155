<template>
  <div v-if="offer">
    <div class="q-pa-md row">
      <div class="col-sm-5 row items-center">
        <img
          :src="offer.image || fallbackImage"
          style="width: 100%; max-height: 250px; object-fit: contain;"
          @error="onImageLoadFailure"
        >
      </div>

      <div class="col-sm-7">
        <form-builder :schema="schema" />
      </div>
    </div>

    <q-list
      bordered
      separator
      class="rounded"
    >
      <q-item class="row items-center">
        <h6 class="q-my-none q-mr-md">
          {{ $t('Pallet') }}
        </h6>

        <div>
          <q-input
            :model-value="offer.sku"
            type="text"
            standout="bg-teal text-white"
            :label="$t('Barcode')"
            :disable="disabled"
            class="text-light"
            label-color="light"
            @update:model-value="handleBarcodeChange"
            required
          />
        </div>
      </q-item>

      <q-item
        v-for="(place, i) in currentPlaces"
        :key="place.id"
        class="q-pa-none"
      >
        <div class="full-width">
          <div class="row items-center full-width border-bottom q-py-sm q-px-md">
            <q-btn
              color="negative"
              :disable="currentPlaces.length < 2 || disabled"
              icon="delete"
              flat
              @click="handleRemovePlace(place)"
            />

            <h6 class="q-my-none q-ml-sm">
              {{ $t('Box No') + (i + 1) }}
            </h6>

            <q-space />

            <q-btn
              color="light-blue-9"
              :label="$t('Add product')"
              :disable="disabled"
              no-caps
              @click="handleAddItem(place)"
            />
          </div>

          <q-table
            row-key="id"
            class="full-width table--only-header"
            :rows-per-page-label="$t('Rows per page')"
            :rows="place.items || []"
            :columns="columns"
            :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: (place.items || []).length }"
            virtual-scroll
            flat
          >
            <template v-slot:body="props">
              <product-row
                :disabled="disabled"
                :data="props"
                @dblclick="handleEditItem(place, props.row)"
                @delete="handleRemove(place, props.row)"
              />
            </template>

            <template v-slot:bottom-row>
              <q-tr>
                <q-td class="border-top border-right text-center">
                  {{ $t('Total') }}
                </q-td>

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top" />

                <q-td class="border-top">
                  {{ getPlaceItemsCount(place) }}
                </q-td>

                <q-td class="border-top">
                  {{ getPlaceTotalSum(place).toFixed(2) }}
                </q-td>

                <q-td class="border-top" />
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-item>

      <q-item
        v-if="currentPlaces.length <= 0"
        class="justify-center text-subtitle1 items-center"
      >
        <div>
          {{ $t('There is no boxes with products. ') }}
        </div>

        <div>
          {{ $t('You can add box via button below.') }}
        </div>
      </q-item>

      <q-item class="justify-center items-center q-pb-md">
        <q-btn
          color="light-blue-9"
          class="q-mt-sm"
          :label="$t('Add box')"
          :disable="disabled"
          @click="handleAddPlace"
        />
      </q-item>
    </q-list>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ProductRow from '../../components/products/ProductRow.vue'

export default {
  name: 'PalletProduct',
  components: {
    ProductRow
  },
  emits: ['change'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      currentPlaces: [],
      columns: [
        {
          name: 'selected',
          label: `${this.$t('Mark')} (${this.$t('optional')})`,
          align: 'center',
          classes: 'border-right',
          headerClasses: 'border-right'
        },
        {
          name: 'image',
          label: this.$t('Image'),
          align: 'left'
        },
        {
          name: 'id',
          label: this.$t('Id'),
          align: 'left'
        },
        {
          name: 'name',
          label: this.$t('Name'),
          align: 'left'
        },
        {
          name: 'payment',
          label: this.$t('Payment'),
          align: 'left'
        },
        {
          name: 'estimatedCost',
          label: this.$t('Estimated Cost'),
          align: 'left'
        },
        {
          name: 'count',
          label: this.$t('Count'),
          align: 'left'
        },
        {
          name: 'total',
          label: this.$t('Total'),
          align: 'left'
        },
        {
          name: 'actions',
          label: '',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'offer'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                required: true,
                disabled: this.disabled,
                onChange: name => {
                  this.updateOffer({ name })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.article,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-sm',
                label: this.$t('Article'),
                disabled: this.disabled,
                required: true,
                onChange: article => {
                  this.updateOffer({ article })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Store'),
                wrapperStyleClasses: 'col-12 q-pa-sm',
                value: this.offer._embedded.shop,
                disabled: !!this.offer && !!this.offer._embedded.shop,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [{ field: 'state', type: 'in', values: ['active', 'blocked'] }]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: shop => {
                  this.updateOfferEmbedded({ shop })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'tag',
                value: this.offer.barcodes || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Barcodes'),
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  this.updateOffer({ barcodes: [...this.offer.barcodes, value ] })
                },
                onRemove: (value) => {
                  this.updateOffer({ barcodes: this.offer.barcodes.filter(x => x !== value) })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.image,
                wrapperStyleClasses: 'col-12 q-pa-sm',
                disabled: this.disabled,
                label: this.$t('Image'),
                onChange: image => {
                  this.updateOffer({ image })
                  this.$emit('change', this.offer)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateOffer',
      'updateOfferEmbedded'
    ]),
    handleBarcodeChange (sku) {
      this.updateOffer({ sku })
    },
    handleAddPlace () {
      this.currentPlaces = [
        ...this.currentPlaces,
        { id: Math.floor(Math.random() * (9999999999) + 1), items: [] }
      ]
    },
    getPlaceTotalSum (place) {
      return place.items.reduce((acc, item) => {
        return acc + item.price
      }, 0)
    },
    getPlaceItemsCount (place) {
      return place.items.length
    },
    handleRemovePlace (place) {
      this.currentPlaces = this.currentPlaces.filter(({ id }) => id !== place.id)
    },
    handleRemove (place, item) {
      this.currentPlaces = this.currentPlaces.map(p => {
        if (p.id === place.id) {
          p.items = p.items.filter(i => i.extId !== item.extId)
        }

        return p
      })
    },
    handleEditItem () {},
    handleAddItem () {},
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    }
  }
}
</script>
