<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 60vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ 'Statistics' }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="productsOffferStatistics"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          @request="onRequest"
          virtual-scroll
          binary-state-sort
          flat
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

            <template v-slot:body="props">
              <q-tr
                :props="props"
              >
                <q-td
                  key="number"
                  :props="props"
                  auto-width
                >
                    {{ props.rowIndex +  1 }}
                </q-td>
                <q-td
                  key="created"
                  :props="props"
                  class="text-subtitle1"
                >
                    <div v-if="props.row && !props.row.document">
                        <p class="q-ma-none text-caption">
                            {{ $moment((typeof props.row.updated === 'object' ? props.row.updated.date : props.row.updated)).format(appOptions.formats.fullDate) }}
                        </p>
                    </div>
                    <div v-else>
                        <p class="q-ma-none text-caption">
                            {{ $moment((typeof props.row.created === 'object' ? props.row.created.date : props.row.created)).format(appOptions.formats.fullDate) }}
                        </p>
                    </div>
                </q-td>

                  <q-td
                    key="documentType"
                    :props="props"
                    class="text-subtitle1"
                    :class="getRowColor(props.row)"
                  >
                    <div v-if="props.row && !props.row.document">
                        {{ 'Expence' }}
                    </div>
                      <div v-else>
                        {{ 'Income' }}
                      </div>
                  </q-td>

                  <q-td
                    key="count"
                    :props="props"
                  >
                    <span v-if="props">
                      {{ props.row.count }}
                    </span>

                    <span v-else>--</span>
                  </q-td>

                  <q-td
                    key="document"
                    :props="props"
                  >
                      <div v-if="props.row.document">
                          {{`Created by document ${props.row.document}`}}
                      </div>
                      <div v-else>
                          {{'--'}}
                      </div>
                  </q-td>

                  <q-td
                    key="order"
                    :props="props"
                  >
                      <div  v-if="props.row.order">
                        <span>
                          {{props.row.order}}
                        </span>
                      </div>
                      <div v-else>
                          <span>{{"--"}}</span>
                      </div>


                  </q-td>
              </q-tr>
            </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Utils
import { buildQuery } from '../../utils/query-utils'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'ProductsStatisticsTableModal',
  data () {
    return {
      isOpen: false,
      isLoading: false,
      offer: null,
      shop: null,
      pagination: {
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      stateColor: {
        new: 'lime-5 text-white',
        confirmed: 'lime-4 text-white',
        complete: 'lime-3 text-white',
        rejected: 'red-4 text-dark'
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Date'),
          name: 'created',
          align: 'center'
        },
        {
          label: this.$t('Document type'),
          name: 'documentType',
          align: 'center'
        },
        {
          label: this.$t('Count'),
          name: 'count',
          align: 'center'
        },
        {
          label: this.$t('Document'),
          name: 'document',
          align: 'center'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'center'
        }
      ],
      tasks: [],
      isTasksLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'productsOffferStatistics',
      'appOptions'
    ]),
  },
  methods: {
    ...mapActions([
      'loadProductsOfferStatisticsService',
    ]),
    ...mapMutations([
      'setProductsOfferStatistics',
    ]),
    open (offer, shop) {
      this.offer = offer
      this.isOpen = true
      this.shop = shop
      this.onRequest({pagination: this.pagination})
    },
    close () {
      this.isOpen = false
    },
    getRowColor (row) {
      if (row.document) {
        return 'bg-green-6'
      } else {
        return 'bg-negative'
      }
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)


      if (!query.filter) {
        query.filter = []
      }
      if(this.shop && this.shop._embedded && this.shop._embedded.parent) {
        query.criteria = {
          productOffer: this.offer.id,
          shop: this.shop._embedded.parent.id
        }
      } else {
        query.criteria = {
          productOffer: this.offer.id,
          shop: this.shop.id
        }
      }



      this.isLoading = true

      return this.loadProductsOfferStatisticsService(query)
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
