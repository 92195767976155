<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('EAV Attributes') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <div
        v-if="isLoading"
        class="text-primary row fit justify-center items-center"
        style="min-height: 300px;"
      >
        <q-spinner
          color="light-blue-9"
          size="3rem"
        />
      </div>

      <q-card-section v-else class="text-center">
        <q-scroll-area style="height: 400px;" visible>
        <slot />

        <div v-if="eavs.length <= 0" class="text-subtitle1">
          {{ $t('No EAV Atributes') }}
        </div>

        <form-builder v-else :schema="schema"  />
        </q-scroll-area>
      </q-card-section>

      <q-card-section v-if="!isLoading" class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Ok')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Utils
import _ from 'lodash'

export default {
  name: 'EavModal',
  emits: ['submit'],
  props:{ 
    eav:{
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      model: null,
      options: {},
      eavs: [],
      isLoading: false
    }
  },
  computed: {
    schema () {
      return {
        groups: this.eavs.reduce((acc, eav) => {
          return [...acc, ...this.createSchema(eav.element)]
        }, [])
      }
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    getModel (model) {
      if (!this.model && Array.isArray(model)) {
        return {}
      }

      return _.cloneDeep(model)
    },
    open (model = {}, options) {
      this.options = options
      this.model = this.getModel(model)
      this.isOpen = true
      this.loadFields()
    },
    createSchema (groups) {
      return [
        {
          styleClasses: 'row',
          fields: groups.reduce((acc, group) => {
            return [
              ...acc,
              ...Object.values(group).map(field => {
                const newField = this.$utils.field.create(this.model, field.spec ? field.spec: field)

                if (newField) {
                  newField.wrapperStyleClasses = 'col-12 q-pa-xs'
                } else {
                  console.debug(field)
                }

                return newField
              })
            ]
          }, [])
        }
      ]
    },
    loadFields () {
      this.isLoading = true
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'backendEntity', value: this.options.entityClass }
        ]
      }

      return this.$service.eav.getAll(query)
        .then(data => {
          this.eavs = data.items
          return data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    save () {
      this.close()
      this.$emit('submit', this.model)
    }
  }
}
</script>