// Quasar
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import { store } from '../vueX/store'

/**
 * Do Copy
 *
 * @param {String} content
 *
 * @returns {Promise<any>}
 */
export function doCopy (content) {
  return copyToClipboard(content)
    .then(e => {
      store.commit('addNotification', 'Copied')
    })
    .catch(e => {
      store.commit('addErrorNotification', 'Can not copy')
    })
}
