<template>
  <q-btn
    class="full-width"
    :class="option.className"
    :style="option.style || ''"
    :disable="option.disabled"
    :color="option.color || 'dark'"
    text-color="white"
    size="sm"
    no-caps
    @click="() => {
      if (option.onClick) {
        option.onClick()
      }
    }"
  >
    <div
      class="row items-center full-width"
      style="overflow-x: hidden;"
    >
      <q-icon
        v-if="option.icon && (hasIcon || option.hasIcon)"
        :name="option.icon"
        class="q-mr-xs"
        :class="option.hasIcon ? '' : 'hide-on-mobile'"
      />

      <div v-if="option.label" class="col">
        {{ $t(option.label) }}
      </div>
    </div>
  </q-btn>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    option: {
      type: Object,
      required: true
    },
    hasIcon: {
      type: Boolean,
      default () {
        return true
      }
    }
  }
}
</script>
