<template>
  <div>
    <!-- When you set dropdown-icon with not existing icon it remove icon -->
    <q-btn-dropdown
      :color="color[model.color] || model.color"
      :text-color="model.color === 'inverse' ? 'white' : 'dark'"
      class="fit q-mt-sm"
      content-class="q-pt-xs text-left"
      :label="$t(model.value.title)"
      :disable="!!model.disabled"
      :dropdown-icon="hasOptions ? undefined : 'no icon'"
      no-caps
      dense
    >
      <q-list
        v-for="section in groups"
        :key="section.id"
        :class="'rounded-top q-pt-xs bg-' + (color[section.color] || section.color)"
        style="min-width: 150px; margin-top: -5px !important;"
      >
        <q-toolbar
          v-if="section.group && model.hasToolbar"
          :class="`bg-${color[section.color] || section.color} text-dark`"
          style="min-height: 28px;"
        >
          <q-toolbar-title class="text-caption">
            {{ $t(section.group) }}
          </q-toolbar-title>
        </q-toolbar>

        <q-item
          v-for="button in section.buttons"
          :key="button.id"
          :class="button.color ? `bg-${button.color}` : ''"
          v-close-popup
          clickable
          @click="() => {
            model.value = button
            model.color = button.color || section.color

            if (model.onClick) {
              model.onClick(button.id)
            }
          }"
        >
          <q-item-section>
            <q-item-label :class="textColor[section.color] || 'text-dark'">
              {{ $t(button.title) }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </div>
</template>

<script>
export default {
  name: 'SuperDropdown',
  props: {
    model: {
      type: Object,
      default () {
        return {
          options: []
        }
      }
    }
  },
  data () {
    return {
      textColor: {
        inverse: 'inverse text-white'
      },
      color: {
        default: 'grey-2 text-dark',
        inverse: 'inverse text-white'
      }
    }
  },
  computed: {
    currentEnableFields () {
      return this.model.value.transitions || []
    },
    groups () {
      return this.model.options.reduce((acc, group) => {
        const newGroup = { ...group }
        const buttons = newGroup.buttons.filter(button => this.currentEnableFields.includes(button.id))

        if (buttons.length > 0) {
          newGroup.buttons = buttons
          acc.push(newGroup)
        }

        return acc
      }, [])
    },
    hasOptions () {
      return this.currentEnableFields.length > 0
    }
  }
}
</script>
