<template>
  <div class="q-mb-sm">
    <q-expansion-item
        class="shadow-1 overflow-hidden"
        style="border-radius: 30px; min-width: 166px;"
        size="sm"
        dense
        dense-toggle
        :header-class="normalItems.count > 0 ? 'bg-light-blue-9 text-white text-left' : 'bg-negative text-white text-left'"
        expand-icon-class="text-white expand-icon--sm"
    >
      <template v-slot:header>
        <q-item-section class="border-right">
          {{ $t('On hand') + ': ' + normalItems.count }}
        </q-item-section>

        <q-tooltip>
          <div class="mb-2 f-s-14">
            {{ $t('Warehouses') }}
          </div>

          <div
              v-for="warehouse in normalItems.warehouses"
              :key="warehouse"
              class="mb-1"
          >
            {{ warehouse }}
          </div>
        </q-tooltip>
      </template>

      <q-card>
        <table
            class="table table-valign-middle rounded text-center"
            :class="$q.dark.isActive ? 'text-white' : ''"
        >
          <thead>
          <tr>
            <th>{{ $t('Status') }}</th>

            <th>{{ $t('Amount') }}</th>

            <th>{{ $t('Warehouse') }}</th>
          </tr>
          </thead>

          <tbody>
          <tr
              v-for="item in filteredItems"
              :key="item.id"
              class="clickable"
              @click="handleDetails(item)"
          >
            <td>
              <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[item.state]}`">
                {{ $t(stateName[item.state] || item.state) }}
              </label>
            </td>

            <td>{{ item.count }}</td>

            <td>{{ item.warehouse }}</td>
          </tr>
          </tbody>
        </table>
      </q-card>
    </q-expansion-item>

    <items-info-modal ref="itemsInfo"/>
  </div>
</template>

<script>
// Components
import ItemsInfoModal from '../modals/ItemsInfoModal.vue'

export default {
  name: 'ItemsTable',
  components: {
    ItemsInfoModal
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    offer: {
      type: Object,
      default () {
        return null
      }
    },
    showDetails: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isVisible: false,
      stateName: {
        normal: 'On hand',
        booked: 'Booked',
        blocked: 'Blocked',
        new: 'Received'
      },
      itemStates: {
        new: 'grey-5',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green',
        normal: 'success',
        expected: 'green',
        missing: 'grey'
      }
    }
  },
  computed: {
    filteredItems () {
      if (!this.items) {
        return []
      }

      return this.items.filter(x => x)
    },
    normalItems () {
      return this.items.reduce((acc, item) => {
        if (!item) {
          return acc
        }

        if (item.state === 'normal') {
          acc.count += Number(item.count)

          if (!acc.warehouses.includes(item.warehouse)) {
            acc.warehouses.push(item.warehouse)
          }
        }

        return acc
      }, { count: 0, warehouses: [] })
    },
    arrow () {
      return this.isVisible
          ? 'up'
          : 'down'
    }
  },
  methods: {
    handleClick () {
      this.isVisible = !this.isVisible
    },
    handleDetails (item) {
      if (!this.showDetails) {
        return
      }

      if (item.state === 'shipped') {
        return
      }

      const options = {}

      if (item.state === 'blocked' || item.state === 'booked') {
        options.description = this.$t('The items are booked for the following reserves') + ':'
      } else if (item.state === 'normal') {
        options.description = this.$t('On hand items') + ':'
      }

      this.$refs.itemsInfo.open({
        productOffer: this.offer.id,
        state: item.state,
        warehouse: item.warehouse
      }, [{ field: 'warehouse', alias: 'i' }, { field: 'productOffer', alias: 'i' }, { field: 'place', alias: 'i' }, {
        field: 'state',
        alias: 'i'
      }, { field: 'reserve', alias: 'i' }], options)
    }
  }
}
</script>

<style>
.expand-icon--sm {
  padding-left: 10px !important;
}
</style>
