<template>
  <q-btn-dropdown
    color="dark"
    size="sm"
    class="full-width"
    :class="option.className || ''"
    :style="option.style || ''"
    :label="$t(option.label)"
    no-caps
  >
    <q-list>
      <q-item
        v-for="item in option.options"
        :key="item.id"
        v-close-popup
        :disable="item.disabled"
        clickable
        @click="(e) => {
          if (item.disabled) {
            return
          }

          typeof item.onClick === 'function' && item.onClick(e)
          typeof option.onClick === 'function' && option.onClick(item)
        }"
      >
        <q-item-section
          v-if="item.icon"
          avatar
          style="min-width: 28px;"
        >
          <q-icon
            :name="item.icon"
            class="q-mr-xs"
          />
        </q-item-section>

        <q-item-section>
          <q-item-label>
            {{ $t(item.label) }}
          </q-item-label>
        </q-item-section>
        <q-tooltip v-if="item.tooltip">
          {{item.tooltip}}
        </q-tooltip>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  name: 'ActionDropdown',
  props: {
    option: {
      type: Object,
      required: true
    }
  }
}
</script>
