<template>
  <div
    style="min-width: 200px"
    :style="option.style || ''"
  >
    <super-dropdown :model="option" />
  </div>
</template>

<script>
import SuperDropdown from '../super-dropdown/SuperDropdown'

export default {
  name: 'ActionSuperDropdown',
  components: {
    SuperDropdown
  },
  props: {
    option: {
      type: Object,
      required: true
    }
  }
}
</script>
