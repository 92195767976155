<template>
  <div class="row items-center justify-center q-px-md q-py-sm border-bottom">
    <div
      v-if="!data.disabledMaxClick"
      class="text-center text-subtitle2 q-mr-md q-pt-xs relative"
    >
      <div
        v-if="options.maxDescription"
        class="absolute-top"
        style="font-size: 10px;"
      >
        {{ $t(options.maxDescription)}}
      </div>

      <q-btn
        ref="maxBtn"
        :label="options.maxCount || data.max"
        :disable="data.max <= 0"
        color="primary"
        size="28px"
        class="q-btn--rounded-xs"
        style="min-width: 110px;"
        outline
        @click="handleMax"
      />

      <div class="q-mt-xs text-weight-bold">
        {{ $t(options.max)}}
      </div>
    </div>

    <div class="text-center q-pt-xs text-subtitle2">
      <q-btn
        ref="currentBtn"
        :label="data.current"
        color="positive"
        size="28px"
        class="q-btn--rounded-xs"
        style="min-width: 110px;"
        outline
      >
        <q-badge v-if="change !== 0" floating :label="change > 0 ? `+${change}` : `${change}`" color="light-blue" />
      </q-btn>

      <div class="q-mt-xs text-weight-bold row justify-center items-center relative">
        {{ $t(options.current) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuantitySection',
  props: {
    options: {
      type: Object,
      default () {
        return {
          isZero: false,
          maxDescription: 'tap to add all',
          max: 'Expected',
          current: 'Scanned'
        }
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      change: 0
    }
  },
  watch: {
    data: {
      handler (value, oldValue) {
        if (this.$refs.currentBtn && this.$refs.currentBtn.$el) {
          this.$refs.currentBtn.$el.focus()
        }

        this.change = value.current - oldValue.current

        setTimeout(() => {
          if (this.$refs.currentBtn && this.$refs.currentBtn.$el) {
            this.$refs.currentBtn.$el.blur()
          }
        }, 300)

        if (!this.options.maxCount && value.max !== oldValue.max) {
          if (this.$refs.maxBtn && this.$refs.maxBtn.$el) {
            this.$refs.maxBtn.$el.focus()
          }

          setTimeout(() => {
            if (this.$refs.maxBtn && this.$refs.maxBtn.$el) {
              this.$refs.maxBtn.$el.blur()
            }
          }, 300)
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.options.isZero) {
      this.change = 0
    }
  },
  methods: {
    handleMax () {
      if (this.data.disabledMaxClick) {
        return
      }

      this.$emit('click', { event: 'counter.submit', data: { value: this.data.max } })
    }
  }
}
</script>
