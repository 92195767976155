<template>
  <q-dialog
      v-model="isOpen"
      persistent
      class="modal-md"
  >
    <q-card class="text-center">
      <q-card-section class="row q-px-xs">
        <div class="col text-h6 no-copy">
          {{ 'What labels do you want to print?' }}
        </div>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="handleClose"
        />
      </q-card-section>

      <q-card-section class="q-pb-none text-center q-mb-md">
        <q-select
            outlined
            input-style="text-align: center; font-size: 2rem"
            class="full-width"
            :model-value="barcode"
            option-label="label"
            :options="barcodes"
            stack-label
            label="Barcode"
            @update:model-value="handleBarcodeInput"
        />
      </q-card-section>

      <q-card-section class="q-pb-none">
        <q-input
            type="number"
            v-model="quantity"
            autofocus
            :rules="[
              (val) => /^\d+$/.test(val) || 'Invalid input. Please enter a number.',
            ]"
            outlined
            input-style="text-align: center; font-size: 2rem"
            @input="handleInput"
        />
      </q-card-section>

      <q-card-section class="q-pb-none">
        <q-input
            type="text"
            v-model="text"
            label="Any additional text"
            outlined
            input-style="text-align: center; font-size: 1rem"
        />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
            color="positive"
            :label="$t('Print')"
            no-caps
            :disable="!quantity"
            @click="handleOk"
        />

        <q-btn
            color="negative"
            :label="$t('Discard')"
            no-caps
            @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'PrintOfferBarcodesQuantityModal',
  props: {
    action: {
      type: String,
      default () {
        return 'check'
      }
    }
  },
  data () {
    return {
      isOpen: false,
      quantity: 1,
      shop: null,
      barcodes: [],
      barcode: null,
      text: null,
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    open (offer) {
      this.isOpen = true

      this.offer = offer

      this.shop = this.offer._embedded?.shop
      this.barcodes = this.offer.barcodes
      this.barcode = this.offer.barcodes[0]
    },
    handleClose () {
      this.isOpen = false
    },
    handleOk () {
      this.$service.printer.generateBarcode({
        barcode: this.barcode,
        label: `${this.offer.name}\n${this.shop.name}${this.text ? '\n' : ''}${this.text ? this.text : ''}`, //  / ${this.item.count} \n ${this.acceptance?.id} / ${this.formattedDate} ${this.item.expires ? this.item.expires : ''}
        count: this.quantity,
        size: '50.8x25.4',
        font: 'roboto',
        fontSize: '7'
      })

      this.isOpen = false
    },
    handleInput (value) {
      this.quantity = value
    },
    handleBarcodeInput (value) {
      this.barcode = value
    }
  }
}
</script>

