<template>
  <div>
    <action-header
      :is-loading="!template"
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <form-builder :schema="schema" />

      <div class="row items-center q-pa-xs">
        <div class="col">
          <q-input
            standout="bg-teal text-white"
            type="text"
            :label="$t('Template')"
            :model-value="(template || {}).template"
            required
            @update:model-value="handleTemplateUpdate"
          />
          
        </div>


        <q-btn-dropdown
          color="primary"
          :label="$t('Bind')"
          no-caps
          class="q-ml-sm"
          style="min-width: 100px;"
        >
          <q-list style="min-width: 150px;">
            <q-item
              v-for="field in fields"
              :key="field"
              v-close-popup
              clickable
              @click="addField(field)"
            >
              <q-item-section>
                <q-item-label>
                  {{ field }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
      <q-editor
      v-if="template"
      :model-value="(template || {}).template"
      @update:model-value="handleTemplateUpdate"
      :dense="$q.screen.lt.md"
      :toolbar="[
        [
          {
            label: $q.lang.editor.align,
            icon: $q.iconSet.editor.align,
            fixedLabel: true,
            list: 'only-icons',
            options: ['left', 'center', 'right', 'justify']
          },
          {
            label: $q.lang.editor.align,
            icon: $q.iconSet.editor.align,
            fixedLabel: true,
            options: ['left', 'center', 'right', 'justify']
          }
        ],
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['print', 'fullscreen'],
        [
          {
            label: $q.lang.editor.formatting,
            icon: $q.iconSet.editor.formatting,
            list: 'no-icons',
            options: [
              'p',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'code'
            ]
          },
          {
            label: $q.lang.editor.fontSize,
            icon: $q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'size-1',
              'size-2',
              'size-3',
              'size-4',
              'size-5',
              'size-6',
              'size-7'
            ]
          },
          {
            label: $q.lang.editor.defaultFont,
            icon: $q.iconSet.editor.font,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'default_font',
              'arial',
              'arial_black',
              'comic_sans',
              'courier_new',
              'impact',
              'lucida_grande',
              'times_new_roman',
              'verdana'
            ]
          },
          'removeFormat'
        ],
        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

        ['undo', 'redo'],
        ['viewsource']
      ]"
      :fonts="{
        arial: 'Arial',
        arial_black: 'Arial Black',
        comic_sans: 'Comic Sans MS',
        courier_new: 'Courier New',
        impact: 'Impact',
        lucida_grande: 'Lucida Grande',
        times_new_roman: 'Times New Roman',
        verdana: 'Verdana'
      }"
          />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'

export default {
  name: 'Template',
  components: {
    ActionHeader
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      events: [
        'delivery.services.request.save.post'
      ],
      fields: [
        'id',
        'trackingNumber',
        'deliveryService'
      ],
      states: [
        { id: 'active', name: this.$t('Active') },
        { id: 'inactive', name: this.$t('Inactive') },
        { id: 'deleted', name: this.$t('Deleted') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'template'
    ]),
    page () {
      return {
        id: this.template && this.template.id,
        name: this.template && this.template.id
          ? this.$t('Template')
          : this.$t('New template')
      }
    },
    schema () {
      return {
        isLoading: !this.template,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.template && this.template.name,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: name => {
                  this.updateTemplate({ name })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Event'),
                field: 'event',
                value: this.template && this.template.event,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.events,
                required: true,
                onChange: (event) => {
                  this.updateTemplate({ event })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                field: 'state',
                value: this.template && this.template.state,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.states,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (state) => {
                  this.updateTemplate({ state: state.id })
                  this.hasChange = true
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Channel'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'channel',
                value: this.template && this.template._embedded.channel,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.notificationChannel.getAll(query)
                },
                onChange: channel => {
                  this.updateTemplateEmbedded({ channel })
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    },
    headerModel () {
      if (!this.template) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col text-white mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.template && this.template.id
                ? this.$t('Template ID: ') + this.template.id
                : this.$t('New Template')
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id && !this.template) {
      this.loadTemplate(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewTemplate()

      const owner = JSON.parse(localStorage.getItem('userData'))
      this.updateTemplate({
        _embedded: {
          ...this.template._embedded,
          owner
        }
      })
    }
  },
  unmounted () {
    this.setTemplate(null)
  },
  methods: {
    ...mapActions([
      'loadTemplate',
      'saveTemplate'
    ]),
    ...mapMutations([
      'setNewTemplate',
      'setTemplate',
      'updateTemplate',
      'updateTemplateEmbedded'
    ]),
    addField (field) {
      this.hasChange = true
      this.updateTemplate({
        template: `${this.template.template}{{${field}}}`
      })
    },
    handleTemplateUpdate (template) {
      this.hasChange = true
      this.updateTemplate({ template })
    },
    refresh () {
      this.loadTemplate(this.$route.params.id)
    },
    save () {
      this.isSaveLoading = true

      this.saveTemplate()
        .then(() => {
          this.hasChange = false
          if (!this.template.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
