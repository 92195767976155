<template>
  <div v-if="offer">
    <div
        :style="mode === 'modal' ? '' : 'min-height: 85vh;'"
        class="q-pa-md"
    >
      <div class="row">
        <div class="col-sm-5 row items-center">
          <img
              :src="offer.image || fallbackImage"
              class="clickable"
              style="width: 100%; max-height: 350px; object-fit: contain;"
              @click="handleImageUpload"
              @error="onImageLoadFailure"
          >

          <q-file
              ref="fileInput"
              accept="image/*"
              filled
              class="d-none"
              @update:model-value="handleUpload"
          />
        </div>

        <div class="col-sm-7">
          <form-builder :schema="schema"/>
        </div>
      </div>
    </div>
      <div v-if="isAdministrator || isSupervisior">
        <q-separator/>
        <q-table
            row-key="id"
            :rows="places"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isLoading"
            :filter="filter"
            :rows-per-page-options="[]"
            table-header-class="d-none"
            virtual-scroll
            binary-state-sort
            flat
        >
          <template v-slot:loading>
              <q-inner-loading
                  showing
                  color="primary"
              />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
            >
              <q-td
                  key="image"
                  :props="props"
                  auto-width
              >
                <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[props.row.state]}`">
                    {{ $t(stateName[props.row.state] || props.row.state) }}
                </label>
              </q-td>

              <q-td>
                <h5 class="text-center">{{ props.row.count }}</h5>
              </q-td>

              <q-td
                  key="details"
                  :props="props"
                  style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
              >
                <div class="text-subtitle1">
                <span v-if="props.row._embedded?.place">
                  <place-object
                      :data="props.row._embedded.place"
                      :type="props.row._embedded.place.type"
                      class="q-mb-sm"
                      @click="openPlace(props.row._embedded.place)"
                  />
                </span>

                    <span v-else>--</span>
                </div>

              </q-td>
            </q-tr>
          </template>

        </q-table>
      </div>

    <div v-if="isAdministrator || isSupervisior">
      <q-separator/>
      <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="logs"
          :columns="columnLogs"
          v-model:pagination="paginationLogs"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          :loading="loadingLogs"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequestLogs"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
              :props="props"
              class="clickable"
          >
            <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
            >
              <strong>{{ props.row.id }}</strong>
            </q-td>

            <q-td
                key="place"
                :props="props"
                style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
            >
              <div class="text-subtitle1">
                <span v-if="props.row._embedded?.place">
                  <place-object
                      :data="props.row._embedded.place"
                      :type="props.row._embedded.place.type"
                      class="q-mb-sm"
                  />
                </span>

                <span v-else>--</span>
              </div>
            </q-td>

            <q-td
                key="type"
                :props="props"
            >
              <q-chip v-if="props.row.type === 'move'" class="q-my-none text-center q-px-lg" size="md" square color="red-4">
                {{ props.row.type }}
              </q-chip>
              <q-chip v-else class="q-my-none text-center q-px-lg" size="md" square color="green-4">
                {{ props.row.type }}
              </q-chip>
            </q-td>

            <q-td
                key="count"
                :props="props"
            >
              {{ props.row.count }}
            </q-td>

            <q-td
                key="created"
                :props="props"
            >
              {{ $moment(props.row.created).format(appOptions.formats.fullDate) }}
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <items-info-modal ref="itemsInfo"/>

    <offer-place-change-quantity ref="offerPlaceChange" @submit="searchProductsSelected(this.offer)"/>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'
// Components
import ItemsInfoModal from '../../components/modals/ItemsInfoModal.vue'
import OfferPlaceChangeQuantity from "@/apps/app/components/modals/OfferPlaceChangeQuantity.vue";
import PlaceObject from "@/apps/terminal/components/objects/place-object.vue";
import {buildQuery} from "@/apps/app/utils/query-utils";

export default {
  name: 'Offer',
  emits: ['close', 'submit'],
  components: {
    PlaceObject,
    OfferPlaceChangeQuantity,
    ItemsInfoModal
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    mode: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      places: [],
      place: {},
      isLoading: false,
      path: [],
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received',
        defected: 'Defected',
      },
      counterOptions: {
        allowNegative: true,
        allowNoLimit: true,
        allowAdditionalAction: {
          label: this.$t(`Move`)
        }
      },
      filter: '',
      counterValue: 0,
      totalCount: 0,
      types: [
        { id: 'simple', name: 'Simple' },
        { id: 'grouped', name: 'Grouped' },
        { id: 'bundle', name: 'Bundle' }
      ],
      statuses: [
        { id: 'normal', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
        { id: 'blocked', name: 'Blocked for Sales' },
        { id: 'deleted', name: 'Deleted' },
        { id: 'archived', name: 'Archived' }
      ],
      paginationLogs: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columnLogs: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Place'),
          name: 'place',
          align: 'center'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Count'),
          name: 'count',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ],
      loadingLogs: false,
      logs: [],
    }
  },
  mounted() {
    if(this.isAdministrator || this.isSupervisior ) {
      this.searchProductsSelected(this.offer)
      this.onRequestLogs()
    }
  },
  computed: {
    ...mapGetters([
      'offer',
      'cleanOffer',
      'appOptions',
      'isClient',
      'isSupervisior',
      'isAdministrator'
    ]),
    isPlace () {
      if(this.place) {
        return true
      }
      return false
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                required: true,
                disabled: this.disabled,
                onChange: name => {
                  this.updateOffer({ name })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.article,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Article'),
                disabled: this.disabled,
                required: true,
                onChange: article => {
                  this.updateOffer({ article })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.sku,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                label: this.$t('Sku'),
                disabled: this.disabled,
                required: true,
                onChange: sku => {
                  this.updateOffer({ sku })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'tag',
                value: this.offer.barcodes || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Barcodes'),
                hint: 'Input (scan/write) the product barcode and press the Enter key to save the barcode.',
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  const barcodes = this.offer.barcodes || []
                  this.updateOffer({ barcodes: [...barcodes, value] })
                  this.$emit('change', this.offer)
                },
                onRemove: (value) => {
                  this.updateOffer({ barcodes: this.offer.barcodes.filter(x => x !== value) })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                value: this.offer && this.offer.type,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                disabled: this.disabled,
                field: 'type',
                disabledClean: true,
                customLabel: (row) => {
                  return row && typeof row === 'object'
                      ? this.$t(row.name)
                      : row
                },
                options: this.types,
                onChange: (type) => {
                  this.updateOffer({ type: type.id })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                value: this.offer && this.offer.state,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                disabled: this.disabled,
                field: 'status',
                disabledClean: true,
                customLabel: (row) => {
                  return row && typeof row === 'object'
                      ? this.$t(row.name)
                      : row
                },
                options: this.statuses,
                onChange: (state) => {
                  if (this.offer.state !== 'inactive' && !this.offer.items && state.id === 'archived') {
                    this.addWarningNotification('The product can\'t be archived until it has inventory and is not in state Inactive.')
                  } else {
                    this.updateOffer({ state: state.id })
                    this.$emit('change', this.offer)
                  }
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.offer.price,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Price'),
                disabled: this.disabled,
                required: true,
                onChange: price => {
                  this.updateOffer({ price })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.offer.purchasingPrice,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                label: this.$t('Value'),
                disabled: this.disabled,
                onChange: purchasingPrice => {
                  this.updateOffer({ purchasingPrice })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Store'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.offer._embedded.shop,
                disabled: !!this.cleanOffer,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [{ field: 'state', type: 'in', values: ['active', 'blocked'] }]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: shop => {
                  this.updateOfferEmbedded({ shop })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.offer.image,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Image'),
                onChange: image => {
                  this.updateOffer({ image })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'X',
                field: 'x',
                disabled: this.disabled || (this.offer.id && this.isClient),
                value: this.offer.dimensions.x,
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: x => {
                  this.updateOffer({ dimensions: { ...this.offer.dimensions, x } })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Y',
                disabled: this.disabled || (this.offer.id && this.isClient),
                value: this.offer.dimensions.y,
                field: 'y',
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: y => {
                  this.updateOffer({ dimensions: { ...this.offer.dimensions, y } })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Z',
                disabled: this.disabled || (this.offer.id && this.isClient),
                value: this.offer.dimensions.z,
                field: 'z',
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: z => {
                  this.updateOffer({ dimensions: { ...this.offer.dimensions, z } })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Weight'),
                field: 'weight',
                disabled: this.disabled || (this.offer.id && this.isClient),
                value: this.offer.weight,
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: weight => {
                  this.updateOffer({ weight })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'tag',
                value: this.offer.eans || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                hint: 'Input the product eans and press the Enter key to save the eans.',
                label: this.$t('Eans'),
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }
                  if (this.offer.eans) {
                    const test = Array.from(this.offer.eans)
                    this.updateOffer({ eans: [...test, value] })
                    this.$emit('change', this.offer)
                  } else {
                    this.updateOffer({ eans: [value] })
                    this.$emit('change', this.offer)
                  }

                },
                onRemove: (value) => {
                  const test = Array.from(this.offer.eans)
                  this.offer.eans = test.filter(x => x !== value)
                  this.updateOffer({ eans: this.offer.eans })
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'tag',
                value: this.offer.upcs,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                field: 'upcs',
                hint: 'Input the product upcs and press the Enter key to save the upcs.',
                label: this.$t('Upcs'),
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }
                  if (this.offer.upcs) {
                    const test = Array.from(this.offer.upcs)
                    this.updateOffer({ upcs: [...test, value] })
                    this.$emit('change', this.offer)
                  } else {
                    this.updateOffer({ upcs: [value] })
                    this.$emit('change', this.offer)
                  }
                },
                onRemove: (value) => {

                  const test = Array.from(this.offer.upcs)
                  this.offer.upcs = test.filter(x => x !== value)
                  this.updateOffer({ upcs: [...this.offer.upcs] }, 'update')
                  this.$emit('change', this.offer)
                }
              },
              {
                type: 'input',
                inputType: 'textarea',
                value: this.offer.comment,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Comment'),
                onChange: comment => {
                  this.updateOffer({ comment })
                  this.$emit('change', this.offer)
                }
              },
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateOffer',
      'updateOfferEmbedded',
      'addWarningNotification'
    ]),
    openPlace (place) {
      this.$refs.offerPlaceChange.open(this.offer , place, this.places)
    },
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    searchProductsSelected (offer) {
      this.places = []
      if (!offer) {
        return null
      }


      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'productOffer', value: this.offer.id },
          { type: 'eq', field: 'state', value: 'normal' }
        ],
        group: [
          { field: 'productOffer', alias: 'i' },
          { field: 'place', alias: 'i' },
          { field: 'state', alias: 'i' }
        ]
      }

      return this.$service.storageItemEntity.getAll(query).then((places) => {
        this.places = places.items
      })
    },
    handleUpload (file) {
      if (this.offer) {
        if (file) {
          this.$service.offer.upload(file, this.offer?.id || undefined, this.offer?._embedded?.shop?.id || undefined, 'image')
              .then(value => {
                this.updateOffer({ image: value[file.name] })
              })
        } else {
          this.$service.offer.upload(file)
              .then(value => {
                this.updateOffer({ image: value[file.name] })
              })
        }
      }
    },
    onRequestLogs (data = {}) {
      this.paginationLogs = data.pagination || {}
      const query = buildQuery(this.paginationLogs)

      if (!query.per_page) {
        query.per_page = 25
      }

      if (!query.filter) {
        query.filter = []
      }

      if (this.offer) {
        query.filter.push({ type: 'eq', field: 'productOffer', value: this.offer.id })
      }
      if ( (this.isAdministrator || this.isSupervisior) && this.offer?._embedded?.shop) {
        query.filter.push({ type: 'eq', field: 'shop', value: this.offer._embedded.shop.id})
      }

      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]

      this.loadingLogs = true

      return this.$service.locationLog.getAll(query)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.logs = items

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.loadingLogs = false
          })
    },
    handleImageUpload () {
      this.$refs.fileInput.pickFiles()
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    }
  }
}
</script>
