<template>
  <form
    :class="dense ? '' : 'q-pa-sm'"
    @submit="handleSubmit"
  >
    <q-input
      ref="field"
      v-model="search"
      :standout="dark ? 'bg-dark text-white' : 'bg-light-blue-9 text-white'"
      :label="!placeholder ? $t('Search') : ''"
      :class="`expandable-field ${className}`"
      :dense="dense"
      :placeholder="placeholder"
      @click="onFocus"
      @blur="onFocusOut"
    >
      <template v-slot:append>
        <q-icon
          v-if="isExpandable && isFocused && search !== ''"
          name="clear"
          class="cursor-pointer"
          @click="handleClear"
        />
      </template>

      <template v-slot:after>
        <q-icon
          name="search"
          class="cursor-pointer"
          @click="onFocus"
        />
      </template>
    </q-input>
  </form>
</template>

<script>
export default {
  name: 'Search',
  emits: ['submit'],
  props: {
    defaultSearch: {
      type: String,
      default () {
        return ''
      }
    },
    placeholder: {
      type: String
    },
    autoset: {
      type: Boolean,
      default () {
        return false
      }
    },
    dark: {
      type: Boolean,
      default () {
        return false
      }
    },
    isExpandable: {
      type: Boolean,
      default () {
        return false
      }
    },
    dense: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      search: this.defaultSearch,
      isFocused: false
    }
  },
  computed: {
    className () {
      if (!this.isExpandable) {
        return ''
      }

      return this.isFocused || this.search.length > 0
        ? 'expandable-field--expand'
        : 'expandable-field--collapse'
    }
  },
  mounted () {
    if (this.autoset) {
      this.search = this.$route.query.search || ''

      if (this.search.endsWith(':*')) {
        this.search = this.search.slice(0, this.search.length - 2)
      }
    }
  },
  methods: {
    onFocus () {
      if (!this.isExpandable) {
        return
      }

      this.isFocused = true
      this.$refs.field.focus()
    },
    onFocusOut () {
      if (!this.isExpandable) {
        return
      }

      this.isFocused = false
    },
    handleClear () {
      this.$emit('submit', '')
      this.search = ''
    },
    handleSubmit (e) {
      e.preventDefault()

      this.$emit('submit', this.search)
    }
  }
}
</script>
