<template>
  <q-btn-dropdown
    size="sm"
    class="full-width"
    :color="option.color || 'dark'"
    :class="option.className || ''"
    :style="option.style || ''"
    :label="option.label || ''"
    :loading="isLoading"
    :icon="option.icon || undefined"
    :disable="!!option.disabled || disabled"
    no-caps
  >
    <div ref="scrollTargetRef" style="max-height: 400px;  overflow: auto;">
      <q-infinite-scroll
        @load="onLoad"
        :disable="page >= totalPages || isLoading"
        :offset="250"
        :scroll-target="$refs.scrollTargetRef"
      >
        <q-list>
          <q-item
            v-for="item in (option.options || [])"
            :key="item.id"
            :v-close-popup="option.noAutoclose === undefined || !option.noAutoclose"
            clickable
            @click="(e) => {
              typeof option.onClick === 'function' && option.onClick(item)
            }"
          >
            <q-item-section>
              <q-item-label v-if="option.customLabel">
                <q-icon
                  v-if="option.customIcon"
                  :name="option.customIcon(item).name"
                  :color="option.customIcon(item).color"
                />

                {{ option.customLabel(item) }}
              </q-item-label>

              <q-item-label v-else>
                {{ $t(item.name) }}
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-for="item in options"
            :key="item.id"
            :v-close-popup="option.noAutoclose === undefined || !option.noAutoclose"
            clickable
            @click="(e) => {
              typeof option.onClick === 'function' && option.onClick(item)
            }"
          >
            <q-item-section>
              <q-item-label v-if="option.customLabel">
                <q-icon
                  v-if="option.customIcon"
                  :name="option.customIcon(item).name"
                  :color="option.customIcon(item).color"
                />

                {{ option.customLabel(item) }}
              </q-item-label>

              <q-item-label v-else>
                {{ $t(item.name) }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-item
          v-if="option.noOptions && !isLoading && (options.options || []).length <= 0 && options.length <= 0"
        >
          <q-item-section>
            <q-item-label>
              {{ option.noOptions }}
            </q-item-label>
          </q-item-section>
        </q-item>

        <template v-if="typeof option.onScroll === 'function'" v-slot:loading>
          <div class="row justify-center q-my-md">
            <q-spinner-dots color="primary" size="40px" />
          </div>
        </template>
      </q-infinite-scroll>
    </div>
  </q-btn-dropdown>
</template>

<script>
export default {
  name: 'ActionLazyDropdown',
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      search: '',
      page: 0,
      totalPages: 1,
      options: [],
      disabled: false
    }
  },
  methods: {
    reset () {
      this.isLoading = false
      this.search = ''
      this.page = 0
      this.totalPages = 1
      this.options = []
    },
    onLoad () {
      if (this.page >= this.totalPages || this.isLoading || typeof this.option.onScroll !== 'function') {
        return
      }

      this.page += 1
      this.isLoading = true

      let search = this.search

      if (search && search[search.length - 1] !== '*' && search[search.length - 2] !== ':' && !search.includes('%')) {
        search += search[search.length - 1] === ':'
          ? '*'
          : ':*'
      }

      return this.option.onScroll(search, this.page)
        .then(data => {
          if (this.page <= 1) {
            this.options = data.items
          } else {
            this.options = [...this.options, ...data.items]
          }

          this.totalPages = data.totalPages
          return data
        })
        .catch(() => {
          this.page -= 1
        })
        .finally(() => {
          this.isLoading = false

          if (this.options.length === 0) {
            this.disabled = true
          }
        })
    }
  }
}
</script>
