<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t('Document Templates') }}
        </div>

        <q-space />

        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />

        <q-dialog v-model="isOpenInnerModal">
          <q-card style="min-width: 60vw; overflow: auto;">
            <q-card-section class="row items-center q-pb-none">
              <div class="text-h6">
                {{ $t('Report Templates') }}
              </div>

              <q-space />

              <q-btn icon="close" flat round dense v-close-popup />
            </q-card-section>

            <q-card-section>
              <!-- We put template example in div -->
              <div ref="template" class="document-template" />
            </q-card-section>
          </q-card>
        </q-dialog>
      </q-card-section>

      <q-card-actions class="justify-center">
        <q-btn
          v-close-popup
          :label="$t('Close')"
          color="dark"
          class="q-mr-sm"
          no-caps
        />

        <q-btn
          :label="$t('Ok')"
          color="light-blue-9"
          :disable="!model.template"
          @click="handleGetTemplate"
          no-caps
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'DocumentTemplateModal',
  props: {
    entityClass: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      isOpen: false,
      isOpenInnerModal: false,
      entity: null,
      handler: null,
      services: {
        'Orderadmin\\Accounts\\Entity\\Invoice': 'invoice',
        'Orderadmin\\Storage\\Entity\\Movement\\Acceptance': 'acceptance',
        'Orderadmin\\Products\\Entity\\AbstractOrder': 'order',
        'Orderadmin\\Products\\Entity\\Product\\Offer': 'offer'
      },
      templates: {
        items: []
      },
      model: {
        template: null
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Template'),
                field: 'template',
                value: this.model.template,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                options: this.templates.items,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onChange: (template) => {
                  this.model.template = template
                },
                buttons: [
                  {
                    id: 1,
                    label: this.$t('Example'),
                    color: 'dark',
                    disabled: !this.model.template,
                    onClick: () => {
                      this.isOpenInnerModal = true

                      // Template div is created on next render
                      // When modal is opened
                      this.$nextTick(() => {
                        this.$refs.template.innerHTML = this.model.template.template
                      })
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'stopLoading'
    ]),
    handleGetTemplate () {
      if (!this.services[this.entityClass]) {
        this.addErrorNotification(`Service for ${this.entityClass} is not found!`)
        return
      }

      return this.$service[this.services[this.entityClass]].getTemplate(this.entity, this.model.template.id)
        .then(data => {
          this.$service.printer.print(data, undefined, true)
          this.isOpen = false
          this.stopLoading()
        })
        .catch(error => {
          this.stopLoading()
          this.addErrorNotification(error)
        })
    },
    open (entity, handler) {
      this.entity = entity
      this.handler = handler
      this.isOpen = true

      if (this.templates.items.length <= 0) {
        const query = {
          per_page: 25,
          page: 1,
          filter: [
            { type: 'eq', field: 'state', value: 'active' }
          ]
        }

        if (this.entityClass) {
          query.filter.push({ type: 'eq', field: 'entityClass', value: this.entityClass })
        }

        this.$service.documentTemplate
          .getAll(query)
          .then(result => {
            this.templates = result
            this.model = { template: this.templates.items[0] }
          })
      }
    }
  }
}
</script>
