const placeMatrix = [
  {
    id: '1',
    color: 'indigo',
    group: 'Baskets',
    buttons: [
      { id: 'employee', color: 'indigo-5', title: 'Employee' },
      { id: 'pallet', color: 'indigo-4', title: 'Pallet' }
    ]
  },
  {
    id: '2',
    color: 'cyan',
    group: 'Main',
    buttons: [
      { id: 'room', color: 'cyan-5', title: 'Room' },
      { id: 'section', color: 'cyan-4', title: 'Section' }
    ]
  },
  {
    id: '3',
    color: 'light-green-10',
    group: 'Others',
    buttons: [
      { id: 'static', color: 'light-green-10', title: 'Static' },
      { id: 'dynamic', color: 'light-green-9', title: 'Dynamic' },
      { id: 'sorting', color: 'light-green-8', title: 'Sorting' },
      { id: 'distribution', color: 'light-green-7', title: 'Distribution' },
      { id: 'distribution_rejected', color: 'light-green-6', title: 'Distribution_rejected' },
      { id: 'assembly', color: 'light-green-5', title: 'Assembly' },
      { id: 'universal', color: 'light-green-4', title: 'Universal' },
      { id: 'defected', color: 'light-green-3', title: 'Defected' }
    ]
  }
]

export default placeMatrix

/**
 * Find state option
 *
 * @param {string|number} id
 *
 * @returns {object|null}
 */
export function getStoragePlaceState (id) {
  for (let i = 0; i < placeMatrix.length; i++) {
    const option = placeMatrix[i].buttons.find(x => x.id === id)

    if (option) {
      return option
    }
  }

  return null
}
