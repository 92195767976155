<template>
  <div
    :class="dense ? '' : `card shadow rounded ${bg} text-dark`"
    @click="handleClick"
  >
    <div class="row text-center text-caption no-wrap q-pa-sm justify-center items-center">
      <template
        v-for="(item, i) in path"
        :key="`${item}: ${i}`"
      >
        <div
          :class="dense ? 'q-py-sm' : 'q-px-xs'"
        >
          <strong>{{ item || 0 }}</strong>
        </div>

        <div
          v-if="i + 1 < path.length"
          :class="dense ? 'q-px-sm' : 'q-px-md'"
        >
          -
        </div>
      </template>
    </div>

    <div
      v-if="type"
      class="text-caption text-center q-py-xs border-top"
    >
      <q-badge
        v-if="badge"
        class="q-pa-sm"
        :color="stateColor"
        :label="type"
      />

      <div v-else>
        {{ type }}
      </div>

      <q-tooltip>{{ data.id }}</q-tooltip>
    </div>

    <div
      v-if="appOptions.debug || forceId"
      class="text-subtitle1 text-center q-py-sm border-top"
    >
      <strong>{{ data.id }}</strong>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Config
import { getStoragePlaceState } from './../../config/PlaceMatrix'

export default {
  name: 'NewPickingPlace',
  emits: ['click'],
  props: {
    dense: {
      type: Boolean,
      default () {
        return false
      }
    },
    badge: {
      type: Boolean,
      default () {
        return false
      }
    },
    forceId: {
      type: Boolean,
      default () {
        return false
      }
    },
    bg: {
      type: String,
      default () {
        return 'bg-white'
      }
    },
    type: {
      type: String,
      default () {
        return null
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      path: []
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    stateColor () {
      if (!this.badge) {
        return null
      }

      return (getStoragePlaceState(this.data.type) || { color: '' }).color
    }
  },
  watch: {
    data (value) {
      this.loadPath(value)
    }
  },
  mounted () {
    this.loadPath(this.data)
  },
  methods: {
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    handleClick () {
      this.$emit('click', this.rawData)
    }
  }
}
</script>
