<template>
  <div
      class="row fit"
      style="height: 100vh"
      :class="$q.dark.isActive ? 'bg-black' : 'bg-grey-2'"
  >
    <!--    <div v-if="isExtendedWrehouse" class="col">-->
    <!--      <div class="q-pa-sm text-h6 border-bottom q-mb-sm">-->
    <!--        {{ $t('Step') + ' 1' }}-->
    <!--      </div>-->

    <!--      <form-builder-->
    <!--          ref="formBuilder"-->
    <!--          :schema="schema"-->
    <!--          :activated-fields="activatedFields"-->
    <!--          :no-groups="false"-->
    <!--      />-->
    <!--    </div>-->

    <div
        v-if="!isMobile || (!isExtended)"
        class="col-12 col-md-9 border-left border-right"
    >

      <div class="row">
        <div class="col-md-12 q-pl-xs">
          <form-builder
              ref="formBuilder"
              :schema="schema"
              :activated-fields="activatedFields"
              :no-groups="false"
          />
        </div>
      </div>

      <div class="row">

        <div class="col-md-10 q-pl-xs">
          <barcode-input
              :has-max-width="true"
              :catchAllFalse="true"
              :settings="{catchAll: false}"
              :is-hidden-camera="true"
              @barcode="handleSearch"
          ></barcode-input>
        </div>

        <div class="col-1">
          <q-btn
              outline
              size="lg"
              :color="this.isSearchByBarcode? 'positive': 'dark'"
              class="q-my-sm"
              icon="qr_code_scanner"
              @click="handleAdd"
          />
        </div>

        <div class="col-1">
          <q-btn
              size="sm"
              :color="this.isTableView? 'positive': 'dark'"
              :label="this.isTableView? 'Icon view': 'Table view'"
              class="row q-my-sm"
              no-caps
              @click="handleTableView"
          />
          <q-btn
              size="sm"
              :color="this.ilike? 'positive': 'dark'"
              label="Full search"
              class="row q-my-sm"
              no-caps
              @click="handleIlike"
          />
        </div>

      </div>

      <div
          v-if="page <= 1 && (isLoading || isLoadingParams)"
          class="row justify-center q-my-md"
      >
        <q-spinner-dots color="primary" size="40px"/>
      </div>

      <div
          v-if="offers.length <= 0 && !(isLoadingParams || isLoading)"
          class="
          row
          justify-center
          items-center
          text-center
          q-my-md
          text-subtitle1
        "
          style="min-height: 80vh"
      >
        <div v-if="!shop && !warehouse">
          <div>
            {{ $t('Please select store or warehouse!') }}
          </div>
        </div>

        <div v-else-if="(shop || warehouse) && search">
          {{ $t('There are no products found!') }}

          <p class="q-my-none text-weight-bold q-mb-xs">
            {{ this.search }}
          </p>

          <div v-if="options.allowNoOffer">
            <q-card-actions align="center" class="q-pt-none">
              <q-btn
                  color="light-blue-9"
                  size="sm"
                  style="min-width: 110px;"
                  icon="add_shopping_cart"
                  :label="$t('Add without offer')"
                  :disable="disabled"
                  @click="handleClick"
              />

              <q-btn
                  color="light-blue-9"
                  size="sm"
                  style="min-width: 110px;"
                  icon="batch_prediction"
                  :label="$t('Add offer batch')"
                  :disable="disabled"
                  @click="handleBatchAdd"
              />

              <q-btn
                  color="light-blue-9"
                  size="sm"
                  style="min-width: 110px;"
                  icon="explore"
                  :label="$t('Add tracking number')"
                  :disable="disabled"
                  @click="handleTrackingNumberAdd"
              />
            </q-card-actions>
          </div>
        </div>

        <div v-else>
          <p class="q-my-none">
            {{ $t('There are no products in the store.') }}
          </p>

          <p class="q-my-none">
            {{ $t('Please create products in the ') }}

            <router-link to="/products/entity/simple" class="text-teal">
              {{ $t('"Product"') }}
            </router-link>

            {{ $t('section') }}.
          </p>
        </div>
      </div>

      <div
          v-if="offers.length > 0 && !isTableView"
          ref="scrollTargetRef"
          style="max-height: calc(100vh - 100px); overflow: auto"
      >
        <q-infinite-scroll
            @load="onLoad"
            :disable="page >= totalPages || isLoading"
            :offset="250"
            :scroll-target="$refs.scrollTargetRef"
        >
          <div class="row">
            <div
                v-for="offer in offers"
                :key="offer.id"
                class="col-6 col-md-3 q-pa-xs"
            >
              <product-card
                  :product="offer"
                  :disabled="disabled"
                  :warehouse="warehouse"
                  @hide="handleHideOffer"
                  @click="handleOfferClick"
              />
            </div>
          </div>

          <template v-slot:loading>
            <div class="row justify-center q-my-md">
              <q-spinner-dots color="primary" size="40px"/>
            </div>
          </template>
        </q-infinite-scroll>
      </div>


      <div
          v-else-if="offers.length > 0 && isTableView"
          style="max-height: calc(100vh - 100px); overflow: auto"
      >
        <div class="q-pa-md">
          <q-table
              class="my-sticky-dynamic my-sticky-header-table"
              flat bordered
              :rows="offers"
              :columns="columns"
              :loading="isLoading"
              row-key="index"
              virtual-scroll
              :virtual-scroll-item-size="48"
              :virtual-scroll-sticky-size-start="48"
              :pagination="{page: 0}"
              :rows-per-page-options="[0]"
              ref="myTable"
              :scroll-target="$refs.scrollTargetRef"
              @virtual-scroll="onVirtualScroll"
              style="height: calc(100vh - 180px);"
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td
                    key="image"
                    :props="props"
                    auto-width
                >
                  <img
                      :src="props.row.image ? props.row.image : fallbackImage"
                      style="width: 80px; height: 70px; object-fit: contain;"
                      @error="onImageLoadFailure"
                  >
                </q-td>

                <q-td
                    key="name"
                    :props="props"
                    auto-width
                    style="white-space: pre-wrap"
                >
                  <div>
                    {{ `${props.row.name} (${props.row.id})` }}
                  </div>
                </q-td>

                <q-td
                    key="shop"
                    :props="props"
                    auto-width
                >
                  <div v-if="props.row._embedded && props.row._embedded.shop">
                    {{ `${props.row._embedded.shop.name} (${props.row._embedded.shop.id})` }}
                  </div>
                </q-td>

                <q-td
                    key="price"
                    :props="props"
                    auto-width
                >
                    <span v-if="!props.row.price">
                        -
                    </span>

                  <span v-else>
                        {{ props.row.price }}
                    </span>
                </q-td>

                <q-td
                    key="barcode"
                    :props="props"
                    auto-width
                >
                      <span v-if="!props.row.article">
                          -
                      </span>

                  <span v-else>
                          {{ props.row.article }}
                      </span>
                </q-td>

                <q-td
                    key="barcode"
                    :props="props"
                    auto-width
                >
                    <span v-if="!props.row.sku">
                        -
                    </span>

                  <span v-else>
                        {{ props.row.sku }}
                    </span>
                </q-td>

                <q-td
                    key="onHand"
                    :props="props"
                    auto-width
                >
                  {{ checkOnHand(props.row) }}
                </q-td>

                <q-td
                    key="type"
                    :props="props"
                    auto-width
                >
                  <q-chip :color="chipTypeColor(props.row)" text-color="white">
                    {{ props.row.type }}
                  </q-chip>
                </q-td>

                <q-td
                    key="actions"
                    :props="props"
                    auto-width
                >
                  <q-btn
                      color="positive"
                      icon="add"
                      flat
                      @click="handleOfferClick(props.row)"
                  />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>
    </div>

    <div v-if="isExtended" class="col">
      <div :style="`height: ${height}px; overflow: auto;`">
        <div
            v-if="products.length <= 0"
            class="
            row
            justify-center
            items-center
            text-center
            q-my-md
            text-subtitle1
          "
            style="min-height: 80vh"
        >
          <div>
            <div>
              {{ $t('You didn’t select any products yet!') }}
            </div>

            <div>
              {{ $t('Please select in Step 2.') }}
            </div>
          </div>
        </div>

        <div v-for="(product, i) in products" :key="`${product.id}:${i}`">
          <div v-if="product.state !== 'deleted'">
            <product-grid-row
                :item="options.item?.id == product.id || options.item?.id == product._embedded?.item?.id ? options.item : {}"
                :row="product"
                :is-batch="!!options.batch"
                :selectedProduct="selectedProduct"
                :has-comments="!!options.hasComments"
                :is-unique="options.isUnique || []"
                :is-select="!!options.selectItem"
                :has-barcode="!!options.hasBarcode"
                @change="handleProductChange(i, $event)"
                @delete="handleProductRemove(i, $event)"
                @select="handleSelected"
                class="q-ma-xs"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center q-pa-sm text-subtitle1">
          <strong>
            {{ $t('Total purchasing price') + ': ' }}
          </strong>

          <span>
            {{ totalPurchasingPrice }},
          </span>

          <strong>
            {{ $t('price') + ': ' }}
          </strong>

          <span>
            {{ totalPrice }}
          </span>
        </div>

        <div class="col q-pa-xs">
          <q-btn
              color="negative"
              class="fit"
              :label="$t('Cancel')"
              no-caps
              @click="handleClose"
          />
        </div>

        <div class="col q-pa-xs" v-if="!this.options.item">
          <q-btn
              color="positive"
              class="fit"
              :label="$t('Submit')"
              no-caps
              @click="handleSubmit"
          />
        </div>
      </div>
    </div>

    <q-page-sticky v-if="isMobile" position="bottom-right" :offset="[18, 44]">
      <q-btn
          fab
          :icon="isOpen ? 'close' : 'shopping_cart'"
          color="light-blue-9"
          @click="handleOpenCloseCard"
      >
        <q-badge v-if="!isOpen" color="positive" floating>
          {{ products.length }}
        </q-badge>
      </q-btn>
    </q-page-sticky>
  </div>
</template>

<script>
// Utils
import _ from 'lodash'

// Components
import ProductCard from './ProductCard.vue'
import ProductGridRow from './ProductGridRow.vue'

//vuex
import { mapGetters, mapMutations } from 'vuex'
import BarcodeInput from '@/apps/app/components/barcode-input/BarcodeInput'

export default {
  name: 'ProductsGrid',
  emits: ['close', 'submit', 'on-item-change'],
  components: {
    BarcodeInput,
    ProductCard,
    ProductGridRow
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          batch: false,
          onlyOneShop: false,
          disabledWarehouse: false,
          selectItem: false,
          defaultValues: {},
          allowNoOffer: false,
          hasComments: false,
          acceptance: false
        }
      }
    },
    filter: {
      type: Array,
      default() {
        return []
      }
    },
    rows: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      shops: [],
      isOpen: false,
      search: '',
      isTableView: false,
      products: [],
      offers: [],
      page: 0,
      totalPages: 1,
      isLoading: false,
      isLoadingParams: false,
      activatedFields: ['shop', 'warehouse', 'items.from', 'items.to', 'state', 'type'],
      shop: null,
      selectedProduct: null,
      types: [
        { code: 'simple', name: 'Simple' },
        { code: 'configurable', name: 'Configurable' },
        { code: 'grouped', name: 'Grouped' },
        { code: 'virtual', name: 'Virtual' },
        { code: 'bundle', name: 'Bundle' }
      ],
      states: [
        {
          name: 'Active',
          code: 'normal'
        },
        {
          name: 'In active',
          code: 'inactive'
        },
        {
          name: 'Archived',
          code: 'archived'
        }
      ],
      warehouse: null,
      filterModel: {
        state: [],
        type: this.options.acceptance ? ['simple'] : [],
        items: {
          from: null,
          to: null
        }
      },
      isSearchByBarcode: false,
      searchByBarcode: '',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Barcode'),
          name: 'barcode',
          align: 'left'
        },
        {
          label: this.$t('Sku'),
          name: 'sku',
          align: 'center'
        },
        {
          label: this.$t('On hand'),
          name: 'onHand',
          align: 'center'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'center'
        },
        {
          label: '',
          name: 'actions',
          align: 'center'
        }
      ],
      ilike: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
    isDataLoaded() {
      return this.offers.length > 0 // Check if data is already loaded
    },
    disabled() {
      if (!this.options.onlyOneProduct) {
        return false
      }

      return this.options.onlyOneProduct && this.products.filter(x => x.count > 0).length >= 1
    },
    isMobile() {
      return window.innerWidth < 700
    },
    isExtended() {
      return this.isMobile
          ? this.isOpen
          : true
    },
    schema() {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                ref: 'warehouse',
                type: 'multiselect',
                label: this.$t('Warehouse'),
                wrapperStyleClasses: this.isMobile ? 'col-6 q-pa-xs' : 'col-2 q-pa-xs',
                disabled: this.options.disabledWarehouse || this.filter.find(x => x.field === 'warehouse' && x.values),
                value: this.warehouse,
                field: 'warehouse',
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: async (value) => {
                  if (!this.warehouse || !value) {
                    await this.resetShop(value)
                  } else if (value && this.warehouse && this.warehouse._embedded && this.warehouse._embedded.owner && this.warehouse._embedded.owner.id !== value._embedded.owner.id) {
                    await this.resetShop(value)
                  }

                  this.warehouse = value
                  this.refresh()
                }
              },
              {
                ref: 'shop',
                type: 'multiselect',
                label: this.$t('Stores'),
                wrapperStyleClasses: this.isMobile ? 'col-6 q-pa-xs' : 'col-2 q-pa-xs',
                value: this.shop,
                field: 'shop',
                disabled: this.filter.find(x => x.field === 'shop' && x.values) || (this.options.onlyOneShop && this.products.length > 0),
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'blocked'] }
                    ]
                  }

                  if (this.warehouse && this.warehouse._embedded && this.warehouse._embedded.owner) {
                    query.filter.push({ type: 'eq', field: 'owner', value: this.warehouse._embedded.owner.id })
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: (value) => {
                  this.shop = value
                  this.refresh()

                  if (!this.warehouse && value) {
                    this.loadWarehouseByShop(value)
                  }
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.filterModel.items.from,
                label: this.$t('Quantity from'),
                field: 'items.from',
                wrapperStyleClasses: this.isMobile ? 'col-6 q-pa-xs' : 'col-2 q-pa-xs',
                onChange: value => {
                  this.filterModel.items.from = value
                  this.refresh()
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.filterModel.items.to,
                field: 'items.to',
                label: this.$t('Quantity to'),
                wrapperStyleClasses: this.isMobile ? 'col-6 q-pa-xs' : 'col-2 q-pa-xs',
                onChange: value => {
                  this.filterModel.items.to = value
                  this.refresh()
                }
              },
              {
                ref: 'state',
                type: 'tag',
                label: this.$t('State'),
                wrapperStyleClasses: this.isMobile ? 'col-6 q-pa-xs' : 'col-2 q-pa-xs',
                value: this.filterModel.state,
                options: this.states,
                field: 'state',
                customListItem: (row) => {
                  return this.$t(row.name)
                },
                onRemove: (val) => {
                  this.filterModel.state = this.filterModel.state.filter(x => x !== val)

                  this.refresh()
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  this.filterModel.state = this.filterModel.state.filter(val => {
                    if (val === value.code) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (isExist) {
                    return
                  }

                  this.filterModel.state = [...this.filterModel.state, value.code]

                  this.refresh()
                }
              },
              {
                ref: 'type',
                type: 'tag',
                label: this.$t('Type'),
                wrapperStyleClasses: this.isMobile ? 'col-6 q-pa-xs' : 'col-2 q-pa-xs',
                value: this.filterModel.type,
                options: this.types,
                field: 'type',
                customListItem: (row) => {
                  return this.$t(row.name)
                },
                onRemove: (val) => {
                  this.filterModel.type = this.filterModel.type.filter(x => x !== val)

                  this.refresh()
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  this.filterModel.type = this.filterModel.type.filter(val => {
                    if (val === value.code) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (isExist) {
                    return
                  }

                  this.filterModel.type = [...this.filterModel.type, value.code]

                  this.refresh()
                }
              }
            ]
          }
        ]
      }
    },
    totalPurchasingPrice() {
      return this.products.reduce((acc, item) => {
        return acc + ((Number(item.count) || 0) * (Number(item.purchasingPrice) || 0))
      }, 0).toFixed(2)
    },
    totalPrice() {
      return this.products.reduce((acc, item) => {
        return acc + ((Number(item.count) || 0) * (Number(item.price) || 0))
      }, 0).toFixed(2)
    },
    height() {
      return window.innerHeight - 100
    }
  },
  watch: {
    rows() {
      this.loadCurrentProducts()
    }
  },
  mounted() {
    if (this.options.item?.sku) {
      this.search = this.options.item.sku
    }

    this.loadCurrentProducts()
    Promise.all([this.loadWarehouse(), this.loadShop()])
        .then(() => {
          if (!this.shop && !this.warehouse) {
            return
          }

          this.onLoad()
        })
  },
  methods: {
    ...mapMutations(['addErrorNotification']),
    resetShop(warehouse) {
      this.$refs.formBuilder.$refs.form[0].$refs.shop[0].$refs.shop.reset()
      this.shop = null

      if (warehouse) {
        return this.loadShopByWarehouse(warehouse)
      }

      return Promise.resolve(null)
    },
    loadDefaultWarehouse() {
      if (this.options.defaultValues && this.options.defaultValues.warehouse) {
        this.warehouse = this.options.defaultValues.warehouse
        return Promise.resolve(this.warehouse)
      }

      const warehouseFilter = this.filter.find(({ field }) => field === 'warehouse')

      if (warehouseFilter && warehouseFilter.value) {
        this.isLoadingParams = true
        this.$service.warehouse.get(warehouseFilter.value)
            .then(warehouse => {
              this.warehouse = warehouse
              return warehouse
            })
            .finally(() => {
              this.isLoadingParams = false
            })
      }

      return Promise.resolve(null)
    },
    loadWarehouse() {
      return this.loadDefaultWarehouse()
          .then(warehouse => {
            if (!warehouse || (warehouse._embedded && warehouse._embedded.owner)) {
              return warehouse
            }
            if (typeof warehouse === 'object') {
              this.isLoadingParams = true
              return this.$service.warehouse.get(warehouse.id)
                  .then(warehouse => {
                    this.warehouse = warehouse

                    return warehouse
                  })
                  .finally(() => {
                    this.isLoadingParams = false
                  })
            } else {
              this.isLoadingParams = true
              return this.$service.warehouse.get(warehouse)
                  .then(warehouse => {
                    this.warehouse = warehouse

                    return warehouse
                  })
                  .finally(() => {
                    this.isLoadingParams = false
                  })
            }

          })
          .then(warehouse => {
            if (!warehouse) {
              return
            }

            if ((this.options.defaultValues && this.options.defaultValues.shop) || this.filter.find(({ field }) => field === 'shop')) {
              return warehouse
            }

            return this.loadShopByWarehouse(warehouse)
          })
          .then(() => {
            return this.warehouse
          })
    },
    getOwnerID(value) {
      if (!value || !value._embedded.owner) {
        return null
      }

      if (value._embedded.owner.id) {
        return value._embedded.owner.id
      }

      return value._embedded.owner._links.self.href.split('/').pop()
    },
    loadShopByWarehouse(warehouse) {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'in', field: 'state', values: ['active', 'blocked'] }
        ]
      }

      const owner = this.getOwnerID(warehouse)

      if (owner) {
        query.filter.push({ type: 'eq', field: 'owner', value: owner })
      }

      this.isLoadingParams = true

      return this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            this.shops = items

            if (totalItems === 1) {
              this.shop = items[0]
            }

            return this.shop
          })
          .finally(() => {
            this.isLoadingParams = false
          })
    },
    loadDefaultShop() {
      if (this.options.defaultValues && this.options.defaultValues.shop) {
        this.shop = this.options.defaultValues.shop
        return Promise.resolve(this.shop)
      }

      const shopFilter = this.filter.find(({ field }) => field === 'shop')

      if (shopFilter && shopFilter.value) {
        this.isLoadingParams = true

        this.$service.shop.get(shopFilter.value)
            .then(shop => {
              this.shop = shop
              return shop
            })
            .finally(() => {
              this.isLoadingParams = false
            })
      }

      return Promise.resolve(null)
    },
    loadShop() {
      this.isLoadingParams = true

      return this.loadDefaultShop()
          .then(shop => {
            if (!shop || (shop._embedded && shop._embedded.owner)) {
              return shop
            }

            return this.$service.shop.get(shop.id)
                .then(shop => {
                  this.shop = shop

                  return shop
                })
          })
          .then(shop => {
            if (!shop) {
              return
            }

            if ((this.options.defaultValues && this.options.defaultValues.warehouse) || this.filter.find(({ field }) => field === 'warehouse')) {
              return shop
            }

            return this.loadWarehouseByShop(shop)
          })
          .then(() => {
            return this.warehouse
          })
          .finally(() => {
            this.isLoadingParams = false
          })
    },
    loadWarehouseByShop(shop) {
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      if (shop && shop._embedded.owner) {
        query.filter.push({ type: 'eq', field: 'owner', value: shop._embedded.owner.id })
      }

      this.isLoadingParams = true
      return this.$service.warehouse.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.warehouse = items[0]
            }

            return this.warehouse
          })
          .finally(() => {
            this.isLoadingParams = false
          })
    },
    handleOfferClickWithItem(offer) {
      let i = this.products.findIndex(x => x.id === this.options.item?.id || x._embedded?.item?.id === this.options.item?.id)

      if (i !== -1) {
        this.$emit('on-item-change', {
          item: this.options.item?.id,
          product: {
            _embedded: {
              productOffer: offer
            }
          }
        })

        this.handleClose()
      }

      return this.products
    },
    handleOfferClickNewItem(offer) {
      if (this.options.onlyOneShop && this.products.find(x => x._embedded?.shop?.id !== offer._embedded?.shop?.id)) {
        if (offer._embedded?.shop?.type === 'virtual') {
          if (this.products.find(x => !x._embedded?.productOffer?._embedded?.shop?.id || (x._embedded?.productOffer?._embedded?.shop?.id === offer._embedded?.shop?.id))) {

          } else {
            this.addErrorNotification('It is required products to be from one shop!')
            return
          }
        }
      }

      let productsIndex
      if (offer.id) {
        productsIndex = this.products.findIndex(x => {
          return x._embedded?.productOffer?.id === offer.id && !x.batch && !x.trackingNumber && !x.expires && x.selected
        })
      } else {
        productsIndex = this.products.findIndex(x => {
          return x.barcode === offer.sku && !x.batch && !x.trackingNumber && !x.expires
        })
      }

      const i = (this.options.isUnique && this.options.isUnique.includes(offer.type))
          ? -1
          : productsIndex

      if (i > -1) {
        this.handleProductChange(i, { ...this.products[i], count: this.products[i].count + 1 })

        // this.products.unshift(this.products.splice(i, 1)[0])
      } else {
        const product = {
          count: 1,
          _embedded: {
            productOffer: offer,
            shop: offer._embedded?.shop
          }
        }

        if (this.options.hasBarcode) {
          product.barcode = undefined

          if (Array.isArray(offer.barcodes)) {
            if (offer.barcodes.length === 1) {
              product.barcode = offer.barcodes[0]
            } else {
              const i = offer.barcodes.find(barcode => barcode === this.search)

              if (i) {
                product.barcode = this.search
              }
            }
          }
        }

        this.products.unshift(product)
      }

      if (!this.shop && this.options.onlyOneShop) {
        this.shop = offer._embedded.shop
        this.refresh()
      }

      return this.products
    },
    handleHideOffer (offer) {
      this.offers = this.offers.filter(e=> e.id !== offer.id)
    },
    handleOfferClick(offer) {
      if (this.options?.item?.id) {
        return this.handleOfferClickWithItem(offer)
      } else {
        return this.handleOfferClickNewItem(offer)
      }
    },
    refresh() {
      this.page = 0
      this.totalPages = 1
      this.offers = []
      return this.onLoad()
    },
    handleOpenCloseCard() {
      this.isOpen = !this.isOpen
    },
    handleSearch(barcode) {
      if (this.isSearchByBarcode) {
        this.searchByBarcode = barcode.raw
        this.search = ''
        this.refresh()
      } else {
        this.search = barcode.raw
        this.searchByBarcode = ''
        this.refresh()
      }
    },
    handleBatchAdd() {
      if (this.products.length === 0) {
        this.addErrorNotification('You should add an offer first')
        return
      }

      let i = 0

      this.handleProductChange(i, { ...this.products[i], batch: this.search })
    },
    handleTrackingNumberAdd() {
      if (this.products.length === 0) {
        this.addErrorNotification('You should add an offer first')
        return
      }

      let i = 0

      this.handleProductChange(i, { ...this.products[i], trackingNumber: this.search })
    },
    getFilter(model, alias = []) {
      return Object.entries(model).reduce((acc, [key, value]) => {
        const a = alias.find(({ field }) => field === key) || {}
        const filter = this.$utils.filter.create(key, value, a.name)

        return [
          ...acc,
          ...filter
        ]
      }, [])
    },
    onLoad() {
      if (this.page >= this.totalPages) {
        return
      }

      this.page += 1
      this.isLoading = true

      const query = {
        per_page: 25,
        page: this.page,
        filter: [
          ...this.filter,
          ...(this.options.filter || []),
          ...this.getFilter(this.filterModel)
        ]
      }

      query.filter = query.filter.filter(x => x.field !== 'warehouse' && x.field !== 'shop')

      if (!query.filter.filter((item) => item.field === 'items').length) {
        query.filter.push({ field: 'items', type: 'isnull' })
      }

      // if (this.warehouse && (!this.options.noWarehouseFilter || query.filter.find(x => x.field === 'items'))) {
      //   query.filter.push({
      //     type: 'orx',
      //     conditions: [
      //       // { alias: 'i', field: 'warehouse', type: 'eq', value: this.warehouse.id },
      //       { type: 'like', field: 'items::text', value: this.warehouse.id },
      //       { field: 'items', type: 'isnull' }
      //     ],
      //     where: 'or'
      //   })
      // }

      if (this.shop && !this.options.noShopFilter) {
        query.filter.push({ field: 'shop', type: 'eq', value: this.shop.id })
      }

      if (!this.shop && this.shops.length > 0 && !this.options.noShopFilter) {
        query.filter.push({ field: 'shop', type: 'in', values: this.shops.map(({ id }) => id) })
      }

      let search = this.search

      if (this.ilike) {
        query.filter.push(
            {
              type: 'orx',
              conditions: [
                {
                  field: 'doc::text',
                  type: 'ilike',
                  value: search + `%`
                },
                {
                  field: 'doc::text',
                  type: 'ilike',
                  value: `%` + search
                },
                {
                  field: 'doc::text',
                  type: 'ilike',
                  value: `%` + search + `%`
                }
              ],
              where: 'and'
            }
        )
      } else {
        if (search && search[search.length - 1] !== '*' && search[search.length - 2] !== ':' && !search.includes('%')) {
          search += search[search.length - 1] === ':'
              ? '*'
              : ':*'

          query.search = search
        } else {
          query.search = search
        }
      }

      if (this.searchByBarcode) {
        query.filter.push({ field: 'barcodes::text', type: 'like', value: `%${this.searchByBarcode}%` })
      }

      query.filter.push({ field: 'state', type: 'neq', value: 'hidden'})
      query.allStatuses = true

      return this.$service.offer.getAll(query)
          .then(data => {
            if (this.page <= 1) {
              this.offers = data.items
            } else {
              this.offers = [...this.offers, ...data.items]
            }

            this.totalPages = data.totalPages
            return data
          })
          .catch(() => {
            this.page -= 1
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onVirtualScroll(evt) {
      if (evt.direction === 'increase' && evt.index === evt.to) {
        this.loadMoreData()
      }
    },
    loadMoreData() {
      if (this.isLoading) {
        return
      }

      if (this.page >= this.totalPages) {
        return
      }

      this.page += 1
      this.isLoading = true

      const query = {
        per_page: 25,
        page: this.page,
        search: this.search,
        filter: [
          ...this.filter,
          ...(this.options.filter || []),
          ...this.getFilter(this.filterModel)
        ]
      }

      query.filter = query.filter.filter(x => x.field !== 'warehouse' && x.field !== 'shop')

      if (!query.filter.filter((item) => item.field === 'items').length) {
        query.filter.push({ field: 'items', type: 'isnull' })
      }

      // if (this.warehouse && (!this.options.noWarehouseFilter || query.filter.find(x => x.field === 'items'))) {
      //   query.filter.push({
      //     type: 'orx',
      //     conditions: [
      //       // { alias: 'i', field: 'warehouse', type: 'eq', value: this.warehouse.id },
      //       { type: 'like', field: 'items::text', value: this.warehouse.id },
      //       { field: 'items', type: 'isnull' }
      //     ],
      //     where: 'or'
      //   })
      // }

      if (this.shop && !this.options.noShopFilter) {
        query.filter.push({ field: 'shop', type: 'eq', value: this.shop.id })
      }

      if (!this.shop && this.shops.length > 0 && !this.options.noShopFilter) {
        query.filter.push({ field: 'shop', type: 'in', values: this.shops.map(({ id }) => id) })
      }

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      if (this.searchByBarcode) {
        query.filter.push({ field: 'barcodes::text', type: 'like', value: `%${this.searchByBarcode}%` })
      }

      return this.$service.offer.getAll(query)
          .then(data => {
            if (this.page <= 1) {
              this.offers = data.items
            } else {
              this.offers = [...this.offers, ...data.items]
            }

            this.totalPages = data.totalPages
            return data
          })
          .catch(() => {
            this.page -= 1
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    handleCatchChange() {
      this.areProductsBundle = !this.areProductsBundle
      this.page = 0
      this.onLoad()
    },
    handleClose(e) {
      this.$emit('close', e)
    },
    handleSubmit() {
      this.$emit('submit', {
        shop: this.shop,
        warehouse: this.warehouse,
        products: this.products,
      })
    },
    handleProductRemove(index) {
      this.products = this.products.filter((item, curIndex) => curIndex !== index)
    },
    handleProductChange(index, product) {
      this.products[index] = product
    },
    handleIlike () {
      if (this.ilike) {
        this.ilike = false
        return
      }
      this.isSearchByBarcode = false
      this.ilike = true
    },
    handleTableView() {
      this.isTableView = !this.isTableView
    },
    handleAdd() {
      if (this.isSearchByBarcode) {
        this.isSearchByBarcode = false
        return
      }
      this.isSearchByBarcode = true
      this.ilike = false
      return
    },
    loadCurrentProducts() {
      this.products = _.cloneDeep(this.rows)
    },
    handleSelected(product) {
      this.selectedProduct = product
    },
    onImageLoadFailure(e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    checkOnHand(product) {
      let count = 0
      if (product.items) {
        const result = product.items.filter(e => e.status === 'normal')
        if (result && result.length > 0) {
          result.forEach(e => {
            count += e.count
          })
        }
      }
      return count
    },
    chipTypeColor(product) {
      if (product.type === 'simple') {
        return 'cyan-6'
      } else if (product.type === 'bundle') {
        return 'purple-4'
      } else {
        return 'yellow-6'
      }
    },
    handleClick() {
      this.handleOfferClick({
        'id': null,
        'article': null,
        'type': 'simple',
        'barcodes': [this.search],
        'sku': this.search,
        'dimensions': {},
        'weight': 0,
        'volume': 0,
        'purchasingPrice': null,
        '_embedded': {
          'shop': null
        },
      })

      // this.handleOfferClick({
      //   'id': 6097215,
      //   'article': null,
      //   'name': 'Cafe Escapes, Chai Latte Tea Beverage, Single-Serve Keurig K-Cup Pods, 48 Count (2 Boxes of 24 Pods)',
      //   'type': 'simple',
      //   'image': 'https://ams3.digitaloceanspaces.com/orderadmin-cloud/modules/products/data/190899/6097215.jpg',
      //   'barcodes': ['0071655199104', '0766789655632', '071655199104', '766789655632', 'S/I/PO6097215*'],
      //   'sku': '1049067632',
      //   'dimensions': {},
      //   'weight': 0,
      //   'volume': 0,
      //   'purchasingPrice': null,
      //   'price': 52.99,
      //   'items': [{ 'count': 1, 'state': 'new', 'warehouse': 8625 }],
      //   'state': 'normal',
      //   'created': '2022-09-06 18:52:04+03',
      //   'updated': '2022-09-17 01:31:47+03',
      //   'extId': '1049067632',
      //   '_embedded': {
      //     'shop': {
      //       'id': 190899,
      //       'type': 'shop',
      //       'name': 'Roth Commerce LLC',
      //       'expirationDate': null,
      //       'state': 'active',
      //       'created': '2022-08-23 16:27:38+03',
      //       'updated': '2022-08-23 16:27:38+03',
      //       'docExtended': null
      //     }
      //   },
      //   '_links': { 'self': { 'href': 'https://pro.oawms.com/api/products/offer/6097215' } }
      // })
    }
  }
}
</script>

<style lang="sass">
.my-sticky-header-table
  /* height or max-height is important */
  height: 310px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: white

  thead tr th
    position: sticky
    z-index: 1

  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */











  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px

  /* prevent scrolling behind sticky top row on focus */











  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>
